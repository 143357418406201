import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  Grid,
} from "@mui/material";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { domain } from "../Components/config";
import Mobile from "./Mobile";

const Rebate = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [todayRebateAmount, setTodayRebateAmount] = useState(0);
  const [totalRebateAmount, setTotalRebateAmount] = useState(0);
  const [rebatePercentage, setRebatePercentage] = useState(0);
  const [rebateHistory, setRebateHistory] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabsData = [
    {
      activeIcon: "/assets/All Select.png", // Active state icon
      inactiveIcon: "/assets/All Deselect.png", // Inactive state icon
      label: "All",
      title: "All-Total betting rebate",
    },
    {
      activeIcon: "/assets/Lottery Select.png", // Active state icon
      inactiveIcon: "/assets/Lottery Deselect.png", // Inactive state icon
      label: "Lottery",
      title: "Lottery-Total betting rebate",
    },
    {
      activeIcon: "/assets/Casino Select.png", // Active state icon
      inactiveIcon: "/assets/Casino Deselect.png", // Inactive state icon
      label: "Casino",
      title: "Casino-Total betting rebate",
    },
    {
      activeIcon: "/assets/Rummy Select.png", // Active state icon
      inactiveIcon: "/assets/Rummy Deselect.png", // Inactive state icon
      label: "Rummy",
      title: "Rummy-Total betting rebate",
    },
    {
      activeIcon: "/assets/Slots Selected.png", // Active state icon
      inactiveIcon: "/assets/Slots Deselect.png", // Inactive state icon
      label: "Slots",
      title: "Slots-Total betting rebate",
    },
  ];

  useEffect(() => {
    const fetchRebateData = async () => {
      try {
        const response = await axios.get(`${domain}/rebate`, {
          withCredentials: true,
        });
        const {
          todayRebateAmount,
          totalRebateAmount,
          rebatePercentage,
          rebateHistory,
        } = response.data;
        setTodayRebateAmount(todayRebateAmount);
        setTotalRebateAmount(totalRebateAmount);
        setRebatePercentage(rebatePercentage);
        setRebateHistory(rebateHistory);
      } catch (error) {
        console.error("Error fetching rebate data:", error);
      }
    };

    fetchRebateData();
  }, []);

  return (
    <Mobile>
      <Box
        sx={{
          bgcolor: "#242424",
          minHeight: "100vh",
          maxWidth: "100%",
          margin: "0 auto",
        }}
      >
        <AppBar
          position="static"
          sx={{ bgcolor: "#3f3f3f", color: "white" }}
          elevation={0}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => navigate("/activity")}
            >
              <ArrowBackIosOutlinedIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}
            >
              Rebate
            </Typography>
          </Toolbar>
        </AppBar>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false} // Remove the scroll buttons
          sx={{
            "& .MuiTabs-flexContainer": {
              justifyContent: "flex-start", // Align tabs to the start
            },
            "& .MuiTabs-indicator": {
              display: "none", // Remove the moving underline
            },
            "& .MuiTab-root:first-of-type": {
              marginLeft: "20px", // Add margin to the left of the first tab
            },
          }}
        >
          {tabsData.map((tab, index) => (
            <Tab
              key={index}
              icon={
                <img
                  src={tabValue === index ? tab.activeIcon : tab.inactiveIcon} // Active/Inactive icon based on tab state
                  alt={tab.label}
                  style={{ width: "24px", height: "24px" }}
                />
              }
              label={tab.label}
              sx={{
                minWidth: "100px",
                minHeight: "60px",
                padding: "8px",
                color: tabValue === index ? "#ffffff" : "#a6b1d5", // Text color
                background:
                  tabValue === index
                    ? "linear-gradient(135deg, #f1d78f, #ca9c4a)" // Active tab background
                    : "#333332", // Inactive tab background
                borderRadius: "8px",
                margin: "8px",
                "&.Mui-selected": {
                  color: "#ffffff", // Active tab text color
                },
                "& .MuiTab-iconWrapper": {
                  marginBottom: "4px", // Space between icon and label
                },
                display: "flex",
                flexDirection: "column", // Icon above the label
                alignItems: "center",
                justifyContent: "center",
                textTransform: "none",
                fontSize: "0.75rem",
                fontWeight: "500",
              }}
            />
          ))}
        </Tabs>

        <Box sx={{ mt: 2, px: 2 }}>
          <Card
            variant="outlined"
            sx={{
              p: 2,
              bgcolor: "#333332",
              borderRadius: "12px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            }}
          >
            <Typography
              variant="h6"
              sx={{ mb: 1, color: "#ffffff", textAlign: "left" }}
            >
              {tabsData[tabValue].title}
            </Typography>
            <CardContent sx={{ p: 0 }}>
              <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                <Box
                  component="span"
                  sx={{
                    border: "1px solid #cea44d",
                    borderRadius: "5px",
                    px: 1,
                    py: 0.5,
                    mr: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <VerifiedUserIcon sx={{ color: "#d9ac4f", mr: 0.5 }} />
                  <Typography
                    variant="caption"
                    sx={{ color: "#cda244", fontWeight: "bold" }}
                  >
                    Real-time count
                  </Typography>
                </Box>
              </Box>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#ffffff",
                  mb: 2,
                  textAlign: "left",
                }}
              >
                <AccountBalanceWalletOutlinedIcon
                  sx={{
                    fontSize: 32,
                    verticalAlign: "middle",
                    mr: 1,
                    color: "#d9ac4f",
                  }}
                />
                {todayRebateAmount.toFixed(2)}
              </Typography>
              <Box
                sx={{ bgcolor: "#4d4d4c", borderRadius: "8px", p: 1, mb: 2 }}
              >
                <Typography
                  variant="body2"
                  sx={{ color: "#a8a599", textAlign: "left" }}
                >
                  Upgrade VIP level to increase rebate rate
                </Typography>
              </Box>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      bgcolor: "#4d4d4c",
                      borderRadius: "8px",
                      p: 2,
                      textAlign: "left",
                      height: "30px",
                    }}
                  >
                    <Typography variant="body2" color="#a8a599" sx={{ mt: -1 }}>
                      Rebate rate
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "#FF851B", mb: 2 }}
                    >
                      {rebatePercentage}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      bgcolor: "#4d4d4c",
                      borderRadius: "8px",
                      p: 2,
                      textAlign: "left",
                      height: "30px",
                    }}
                  >
                    <Typography variant="body2" color="#a8a599" sx={{ mt: -1 }}>
                      Total rebate
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "#FF851B" }}
                    >
                      {parseFloat(totalRebateAmount).toFixed(2)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Typography
                variant="body2"
                sx={{
                  color: "#a8a599",
                  fontSize: "11px",
                  textAlign: "left",
                  mb: -3,
                  mt: -2,
                }}
              >
                Automatic code washing at 01:00:00 every morning
              </Typography>
              {/* <Button
      variant="contained"
      fullWidth
      sx={{
        bgcolor: "#0F6518",
        color: "#fff",
        borderRadius: "25px",
        textTransform: "none",
        height: "48px",
        fontSize: "1rem",
        fontWeight: "bold",
        "&:hover": { bgcolor: "#0A4F12" },
      }}
    >
      One-Click Rebate
    </Button> */}
            </CardContent>
          </Card>

          <Typography
            variant="h6"
            sx={{
              textAlign: "left",
              mt: 4,
              mb: 2,
              color: "#fff",
            }}
          >
            <Box
              component="span"
              sx={{
                bgcolor: "#cea44d",
                mr: 1,
                borderRadius: "2px",
                width: "4px",
                height: "20px",
                display: "inline-block",
              }}
            ></Box>
            Rebate History
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{
              bgcolor: "#333332",
              border: "1px solid #cea44d",
              color: "#fff",
              borderRadius: "25px",
              textTransform: "none",
              height: "45px",
              fontSize: "1rem",
              "&:hover": {
                bgcolor: "#333332", // keeps the background color the same on hover
              },
            }}
          >
            All History
          </Button>

          <Box sx={{ mt: 1 }}>
            {rebateHistory.length > 0 ? (
              rebateHistory.map((record) => (
                <Card
                  key={record._id}
                  variant="outlined"
                  sx={{
                    mb: 2,
                    p: 2,
                    bgcolor: "#333332",
                    borderRadius: "12px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="white">
                        Rebate Amount
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#d9ac4e" }}
                      >
                        {record.rebateAmount.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="#fff">
                        Wallet Amount After Rebate
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#d9ac4e" }}
                      >
                        {record.walletAmountAfterRebate.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="#fff">
                        Level Awarded
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#FF851B" }}
                      >
                        {record.levelAwarded}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="#fff">
                        Date
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: "bold", color: "#d9ac4e" }}>
                        {new Date(record.date).toLocaleDateString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <Box sx={{ textAlign: "center", py: 4 }}>
                <img
                  src="../../assets/nodata.png" // Replace with the correct path to your image
                  alt="No data available"
                  style={{ width: "50%", marginBottom: "10px" }} // Adjust size and spacing as needed
                />
                <Typography variant="body1" color="#f5f5f5">
                  No rebate records available
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <br />
      <br />
      <br />
      <br />
    </Mobile>
  );
};

export default Rebate;
