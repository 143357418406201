import React, { useEffect, useState } from "react";
import Mobile from "../Components/Mobile";
import IconButton from "@mui/material/IconButton";
import SmsIcon from "@mui/icons-material/Sms";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Typography, Button, Grid } from "@mui/material";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PeopleIcon from "@mui/icons-material/People";
import { useNavigate } from "react-router-dom";
import { Container, Card, CardContent, LinearProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import axios from "axios";
import { domain } from "./config";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PersonIcon from "@mui/icons-material/Person";

const depositOptions = [
  { amount: 300, bonus: 50 },
  { amount: 500, bonus: 150 },
  { amount: 1000, bonus: 200 },
  { amount: 3000, bonus: 400 },
  { amount: 4000, bonus: 500 },
  { amount: 5000, bonus: 600 },
  { amount: 10000, bonus: 1100 },
  { amount: 50000, bonus: 4100 },
  { amount: 100000, bonus: 5500 },
];

const options = [
  { label: "Copy invitation code", image: "assets/Invi code brown.png" },
  { label: "Subordinate data", image: "assets/acc12.png" },
  {
    label: "Commission details",
    image: "assets/acc1.png",
  },
  { label: "Invitation rules", image: "assets/acc13.png" },
  {
    label: "New Subordinates",
    image: "assets/acc9.png",
  },
  {
    label: "Agent line customer service",
    image: "assets/acc11.png",
  },
  {
    label: "Rebate Ratio",
    image: "assets/Rebate ratio brown.png",
  },
];

const PromotionMain = ({ children }) => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", setVh);
    setVh();

    return () => window.removeEventListener("resize", setVh);
  }, []);

  const navigate = useNavigate();
  const [commissionWallet, setCommissionWallet] = useState(0);
  const [referralCount, setReferralCount] = useState(0);

  const [inviteLink, setInviteLink] = useState("");
  const [lifetimeCommission, setLifetimeCommission] = useState(0);
  const [totalDirectSubordinates, setTotalDirectSubordinates] = useState(0);
  const [totalTeamSubordinates, setTotalTeamSubordinates] = useState(0);
  const [totalAllSubordinates, setTotalAllSubordinates] = useState(0);
  const [copiedCode, setCopiedCode] = useState("");
  const [commission, setCommission] = useState(0);
  const [thisWeekCommission, setThisWeekCommission] = useState(0);
  const [yesterdayCommission, setYesterdayCommission] = useState(0);

  useEffect(() => {
    // Function to fetch subordinate data from the API
    const fetchSubordinateData = async () => {
      try {
        const response = await axios.get(`${domain}/subordinates`, {
          withCredentials: true,
        }); // Adjust the URL if necessary
        const data = response.data;

        // Update total counts
        setTotalDirectSubordinates(data.directSubordinateCount);
        setTotalTeamSubordinates(data.teamSubordinateCount);
        setTotalAllSubordinates(data.totalSubordinateCount);
      } catch (err) {
        console.error("Error fetching subordinate data:", err);
      }
    };

    fetchSubordinateData(); // Call the fetch function
  }, []);

  useEffect(() => {
    const fetchLifetimeCommission = async () => {
      try {
        const { data } = await axios.get(`${domain}/commission-history`, {
          withCredentials: true,
        });

        console.log("data------>", data);
        // Assuming the lifetime commission data is in data.lifetimeCommission
        // Adjust this based on the actual API response structure
        let lifetimeCommissionData = (data.lifetimeCommission || 0).toFixed(2);
        let thisWeekCommissionData = (
          data.previousWeekCommissionTotal || 0
        ).toFixed(2);
        let yesterdayCommissionData = (
          data.yesterdayCommissionTotal || 0
        ).toFixed(2);

        console.log("thisWeekCommission---->", thisWeekCommissionData);

        setYesterdayCommission(parseFloat(yesterdayCommissionData));
        setThisWeekCommission(parseFloat(thisWeekCommissionData));
        setLifetimeCommission(parseFloat(lifetimeCommissionData));
      } catch (err) {
        console.error("Error fetching lifetime commission data:", err);
        // Handle errors as necessary, e.g., set default values or show an error message
        setLifetimeCommission(0);
        setThisWeekCommission(0);
        setYesterdayCommission(0);
      }
    };

    fetchLifetimeCommission();
  }, []); // Empty dependency array to run this effect only once on mount

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${domain}/commission-stats`, {
          withCredentials: true,
        });
        setCommission(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchUserData();
  }, []);

  const [subordinate, setSubordinates] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${domain}/api/subordinates/previous-day`,
          {
            withCredentials: true,
          }
        );
        setSubordinates(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchUserData();
  }, []);

  const [totalCommission, SetTotalCommission] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${domain}/user/totalcommission`, {
          withCredentials: true,
        });
        SetTotalCommission(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchUserData();
  }, [totalCommission]);

  const handleCopyLink = async () => {
    navigate("/invite");
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${domain}/user`, {
          withCredentials: true,
        });
        setUser(response.data.user);
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserData();
  }, []);

  const dataItems = [
    {
      heading: "Number of Registers ",
      value: subordinate?.directSubordinatesCount || 0,
    },
    {
      heading: "Deposit Number ",
      value: subordinate?.directSubordinatesDepositCount || 0,
    },
    {
      heading: "Total Deposit ",
      value: subordinate?.directSubordinatesDepositSum || 0,
    },
    {
      heading: "Number of People Making First Deposit ",
      value: subordinate?.directSubordinatesFirstTimeDepositors || 0,
    },
    {
      heading: "Number of Registers ",
      value: subordinate?.teamSubordinatesCount || 0,
    },
    {
      heading: "Deposit Number ",
      value: subordinate?.teamSubordinatesDepositCount || 0,
    },
    {
      heading: "Total Deposit ",
      value: subordinate?.teamSubordinatesDepositSum || 0,
    },
    {
      heading: "Number of People Making First Deposit ",
      value: subordinate?.teamSubordinatesFirstTimeDepositors || 0,
    },
  ];

  const data = [
    { heading: "This week", value: thisWeekCommission || 0 },
    { heading: "Total commission", value: lifetimeCommission || 0 },
    { heading: "Direct subordinate", value: totalDirectSubordinates || 0 },
    {
      heading: "Total subordinates in team",
      value: totalAllSubordinates || 0,
    },
    { heading: "First Deposits Direct", value: user?.firstDepositsDirect || 0 },
    { heading: "First Deposits Team", value: user?.firstDepositsTeam || 0 },
  ];

  const handleOptionClick = async (option) => {
    switch (option.label) {
      case "Copy invitation code":
        try {
          if (user && user.invitationCode) {
            await navigator.clipboard.writeText(user.invitationCode);
            setCopiedCode(user.invitationCode);
            alert("Invitation code copied to clipboard");
          } else {
            alert("Invitation code not available");
          }
        } catch (err) {
          console.error("Failed to copy invitation code: ", err);
        }
        break;
      case "Subordinate data":
        navigate("/subordinate-data");
        break;
      case "Commission details":
        navigate("/commision-details");
        break;
      case "Invitation rules":
        navigate("/invitation-rules");
        break;
      case "Agent line customer service":
        window.open("https://t.me/Dragon_club_support", "_blank");
        break;
      case "New Subordinates":
        navigate("/newsubordinate");
      case "Rebate Ratio":
          navigate("/rebate-ratio");
        break;
      // Add more cases for other options
      default:
        console.log(`Clicked on option: ${option.label}`);
    }
  };

  const handleDownload = () => {
    // Programmatically click the hidden anchor tag
    const link = document.createElement("a");
    link.href = `https://111club.online/abclottery.apk`; // Change this to the actual path of the APK file on your server
    link.download = "abclottery.apk";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Mobile>
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
        >
          <Box flexGrow={1} sx={{ backgroundColor: "#242424" }}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#3f3f3f",
                padding: "12px 16px",
                color: "white",
              }}
            >
              <Grid item xs={12} textAlign="center">
                <span style={{ fontSize: "1.1rem" }}>Agency </span>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mt={0}
              mb={15}
              sx={{
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                borderRadius: "10px",
              }}
            >
              {/* First Grid */}

              <Grid
                item
                xs={12}
                sx={{
                  borderRadius: "0px 0px 0 0",
                  backgroundImage: 'url("/assets/promotionbg-13880557.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundColor: "#d9ac4f",
                  height: "250px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {/* Header Content */}
                <Typography
                  variant="h5"
                  mt={1}
                  sx={{ color: "#8f5205" }}
                  align="center"
                >
                  {`\u20B9${yesterdayCommission.toFixed(2)}`}
                </Typography>

                <Typography
                  variant="body2"
                  color="#d9ac4f"
                  backgroundColor="#333332"
                  align="center"
                  mt="3px"
                  padding="0.5px 15px"
                  borderRadius="25px"
                >
                  Yesterday's Total commission
                </Typography>

                <Typography
                  variant="caption"
                  color={"#8f5205"}
                  align="center"
                  mt="3px"
                >
                  Upgrade the level to increase commission income
                </Typography>

                {/* Inner Grid Container */}
                <Grid
                  container
                  sx={{
                    maxHeight: "70%",
                    maxWidth: "97%",
                    marginTop: "20px",
                    borderRadius: "8px",
                    boxShadow: 2,
                  }}
                >
                  {/* Header */}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      borderRadius: "8px 8px 0 0",
                      backgroundColor: "#3a3947",
                      padding: "0.1rem",
                    }}
                  >
                    {/* Direct Subordinates */}
                    <Grid
                      container
                      item
                      xs={6}
                      sx={{
                        borderRight: "1px solid #333332",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: "0.5rem",
                      }}
                    >
                      <PersonIcon
                        sx={{ color: "#d6b878", marginRight: "0.4rem" }}
                      />
                      <Typography variant="caption" sx={{ color: "white" }}>
                        Direct subordinates
                      </Typography>
                    </Grid>

                    {/* Team Subordinates */}
                    <Grid
                      container
                      item
                      xs={6}
                      sx={{
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: "0.5rem",
                      }}
                    >
                      <PersonIcon
                        sx={{ color: "#d6b878", marginRight: "0.4rem" }}
                      />
                      <Typography variant="caption" sx={{ color: "white" }}>
                        Team subordinates
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Content */}
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      padding: "7px",
                      backgroundColor: "#333332",
                      borderRadius: "0 0 8px 8px",
                    }}
                  >
                    {[0, 1, 2, 3].map((index) => (
                      <React.Fragment key={index}>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            marginBottom: "5px",
                            borderRight: "1px solid #4d4d4c",
                          }}
                        >
                          <Typography
                            variant="h7"
                            align="center"
                            sx={{
                              color:
                                index === 1
                                  ? "green"
                                  : index === 2
                                  ? "orange"
                                  : "white",
                            }}
                          >
                            {dataItems[index].value}
                          </Typography>
                          <Typography
                            variant="caption"
                            align="center"
                            fontSize={10}
                            sx={{ color: "white", display: "block" }}
                          >
                            {dataItems[index].heading}
                          </Typography>
                        </Grid>

                        <Grid item xs={6} sx={{ marginBottom: "5px" }}>
                          <Typography
                            variant="h7"
                            align="center"
                            sx={{
                              color:
                                index === 1
                                  ? "green"
                                  : index === 2
                                  ? "orange"
                                  : "white",
                            }}
                          >
                            {dataItems[index + 4].value}
                          </Typography>
                          <Typography
                            variant="caption"
                            align="center"
                            fontSize={10}
                            sx={{ color: "white", display: "block" }}
                          >
                            {dataItems[index + 4].heading}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Button
              onClick={handleCopyLink}
              variant="contained"
              sx={{
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px", // Adjust as needed
                marginBottom: "10px",
                background: "linear-gradient(to right, #fae39d, #c49440)",
                "&:hover": {
                  background: "linear-gradient(to right, #fae39d, #c49440)",
                },
                color: "#8f5205",
                borderRadius: "20px",
                fontWeight: "bold" // Adjust as needed
              }}
            >
              Invitation Link
            </Button>
            <div>
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    // Only handle clicks if the option label is not "Copy invitation code"
                    if (option.label !== "Copy invitation code") {
                      handleOptionClick(option);
                    }
                  }}
                  sx={{
                    backgroundColor: "#333332",
                    "&:hover": {
                      backgroundColor: "#333332",
                    },
                    padding: 3,
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={option.image}
                      alt="icon"
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                  </ListItemIcon>
                  <Typography
                    variant="h4"
                    sx={{
                      color: "white",
                      flex: 1,
                      fontSize: "0.9rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between", // Aligns child elements to the ends
                    }}
                  >
                    <span>{option.label}</span>
                    {option.label === "Copy invitation code" &&
                      user &&
                      user.invitationCode && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="inherit"
                            sx={{
                              color: "grey",
                              fontSize: "0.7rem",
                              marginLeft: 2,
                              marginRight: 1,
                            }}
                          >
                            {user.invitationCode}
                          </Typography>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents the click event from bubbling up to the MenuItem
                              handleOptionClick(option); // Call the click handler for the option
                            }}
                          >
                            <ContentCopyIcon sx={{color:"#a7a5a1", fontSize: "1rem"}}  />
                          </IconButton>
                        </Box>
                      )}
                  </Typography>

                  {option.label !== "Copy invitation code" && (
                    <ListItemIcon
                      style={{ marginLeft: "auto", color: "white" }}
                    >
                      <ArrowForwardIcon />
                    </ListItemIcon>
                  )}
                </MenuItem>
              ))}
            </div>

            <Grid
              mt={4}
              sx={{
                backgroundColor: "#333332",
                borderRadius: "8px",
                marginLeft: "auto",
                marginRight: "auto",
                width: "94%",
                marginBottom: "100px",
                padding: "10px",
              }}
            >
              {/* Header */}
              <Grid container alignItems="center" sx={{ marginBottom: "5px" }}>
                <Grid item>
                <img
  src="/assets/Promotion_camera.svg" 
  alt="icon"
  style={{ width: 24, height: 24, marginRight: 8 }}
/>

                </Grid>
                <Grid item>
                  <Typography
                    variant="h7"
                    sx={{
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Promotion data
                  </Typography>
                </Grid>
              </Grid>

              {/* Content */}
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: "white" }}>
                    {thisWeekCommission}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#a7a5a1" }}>
                    {data[0].heading}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: "white" }}>
                    {lifetimeCommission}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#a7a5a1" }}>
                    {data[1].heading}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: "white" }}>
                    {totalDirectSubordinates}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#a7a5a1" }}>
                    {data[2].heading}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: "white" }}>
                    {totalAllSubordinates}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#a7a5a1" }}>
                    {data[3].heading}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* content end */}
          </Box>
          <br />
          <br />
          <br />

          {children}
        </Box>
      </Mobile>
    </div>
  );
};

export default PromotionMain;