import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DiamondIcon from '@mui/icons-material/Diamond';

const BottomNavigationArea = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(location.pathname);
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));



  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      style={{
        position: 'fixed',
        bottom: 0,
        padding: '6px 0',
        backgroundColor: '#333332',
        borderRadius: "15px 15px 0px 0px",
        width: '100%',
        maxWidth:isSmallScreen ? '': "396px",
        margin: 'auto',
      }}
    >
      <BottomNavigationAction
        style={{ color: value === '/home' ? '#DDB96B' : '#BFBFBF' }}
        label="Home"
        value="/home"
        icon={ <img 
          src={value === '/home' ? '/assets/images/Home BDG.svg':'/assets/images/home-3e6a9291.png'} 
          width="30px" 
          height="30px" 
          style={{ 
            color: value === '/home' ? '#DDB96B' : '#BFBFBF' // hide image when src is empty
          }} 
          alt="icon"
        />} 
      />
  <BottomNavigationAction
  style={{ color: value === '/activity' ? '#DDB96B' : '#BFBFBF' }}
  label="Activity"
  value="/activity"
  icon={
    <img 
    src={value === '/activity' ? '/assets/images/Activity BDG.svg':'/assets/images/activity-bb37b07c.png'} 
    width="30px" 
    height="30px" 
    style={{ 
      color: value === '/activity' ? '#DDB96B' : '#BFBFBF' // hide image when src is empty
    }} 
    alt="icon"
  />
    
  }
/>


<BottomNavigationAction
  label="Promotion"
  value="/promotion"
  icon={
    value === '/promotion' ? 
    <img
      src="/assets/BDG Kohinoor.svg" // replace with the actual image path
      alt="Promotion"
      style={{
        width: '50px',
        height: '50px',
        padding: '1px'
      }}
    /> : 
    <img
      src="/assets/BDG Kohinoor.svg" // replace with the actual image path
      alt="Promotion"
      style={{
        width: '50px',
        height: '50px',
        padding: '1px'
      }}
    />
  }
  style={{
    color: value === '/promotion' ? '#DDB96B' : '#DDB96B',
    transform: 'scale(1.3)',
    marginTop: '-30px',
  }}
/>


      <BottomNavigationAction
        style={{ color: value === '/wallet' ? '#DDB96B' : '#BFBFBF' }}
        label="Wallet"
        value="/wallet"
        icon ={
        <img 
        src={value === '/wallet' ? '/assets/images/Wallet BDG.svg':'/assets/images/wallet-dd37d20a.png'} 
        width="30px" 
        height="30px" 
        style={{ 
          color: value === '/wallet' ? '#DDB96B' : '#BFBFBF' // hide image when src is empty
        }} 
        alt="icon"
      />}
      />
      <BottomNavigationAction
  style={{ color: value === '/account' ? '#DDB96B' : '#BFBFBF' }}
  label="Account"
  value="/account"
  icon={
    <img 
    src={value === '/account' ? '/assets/images/Account BDG.svg':'/assets/images/main-53f64122.png'} 
    width="30px" 
    height="30px" 
    style={{ 
      color: value === '/account' ? '#DDB96B' : '#BFBFBF' // hide image when src is empty
    }} 
    alt="icon"
  />
  }
/>

    </BottomNavigation>
  );
};

export default BottomNavigationArea;