import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Avatar,
  Box,
} from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Mobile from './Mobile';
import { useNavigate } from 'react-router-dom'; 
const WinningItem = ({ avatar, nickname, gameName, wins, bonus, time }) => (
<Card sx={{ mb: 2, borderRadius: 2, margin: 1, backgroundColor: "#333332" }}>
  <CardContent>
    <Grid container>
      {/* Game Name Row */}
      <Grid container item xs={12} mb={0.5}>
        <Grid item xs={6} sx={{ backgroundColor: '#4d4d4c', py: 0.5, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
          <Typography variant="body2" color="#a8a5a1" sx={{ textAlign: 'left', fontSize: '0.875rem', ml: 1 }}>
            Game name
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#4d4d4c', py: 0.5, borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
          <Typography variant="body2" color="white" sx={{ textAlign: 'left', fontSize: '0.875rem' }}>
            {gameName}
          </Typography>
        </Grid>
      </Grid>

      {/* Number of Wins Row */}
      <Grid container item xs={12} mb={0.5}>
        <Grid item xs={6} sx={{ backgroundColor: '#4d4d4c', py: 0.5, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
          <Typography variant="body2" color="#a8a5a1" sx={{ textAlign: 'left', fontSize: '0.875rem', ml: 1 }}>
            Number of wins
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#4d4d4c', py: 0.5, borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
          <Typography variant="body2" color="#dd9138" sx={{ textAlign: 'left', fontSize: '0.875rem' }}>
            {wins}X
          </Typography>
        </Grid>
      </Grid>

      {/* Bonus Row */}
      <Grid container item xs={12} mb={0.5}>
        <Grid item xs={6} sx={{ backgroundColor: '#4d4d4c', py: 0.5, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
          <Typography variant="body2" color="#a8a5a1" sx={{ textAlign: 'left', fontSize: '0.875rem', ml: 1 }}>
            Bonus
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#4d4d4c', py: 0.5, borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
          <Typography variant="body2" color="#d23838" sx={{ textAlign: 'left', fontSize: '0.875rem' }}>
            ₹{bonus}
          </Typography>
        </Grid>
      </Grid>

      {/* Winning Time Row */}
      <Grid container item xs={12}>
        <Grid item xs={6} sx={{ backgroundColor: '#4d4d4c', py: 0.5, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
          <Typography variant="body2" color="#a8a5a1" sx={{ textAlign: 'left', fontSize: '0.875rem', ml: 1 }}>
            Winning time
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#4d4d4c', py: 0.5, borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
          <Typography variant="body2" color="#a8a599" sx={{ textAlign: 'left', fontSize: '0.875rem' }}>
            {time}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </CardContent>
</Card>

);

const WinningStar = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const winningItems = [
      { avatar: "/assets/pro1.png", nickname: "912***345", gameName: "Mines", wins: 8.22, bonus: "30.00", time: "2024-08-17 06:10:00" },
      { avatar: "/assets/pro2.png", nickname: "920***678", gameName: "Money Coming", wins: 15.40, bonus: "60.00", time: "2024-08-17 06:15:00" },
      { avatar: "/assets/pro3.png", nickname: "921***789", gameName: "Mines", wins: 12.75, bonus: "40.00", time: "2024-08-17 06:20:00" },
      { avatar: "/assets/pro4.png", nickname: "922***890", gameName: "Money Coming", wins: 25.00, bonus: "80.00", time: "2024-08-17 06:25:00" },
      { avatar: "/assets/pro1.png", nickname: "923***901", gameName: "Mines", wins: 7.85, bonus: "20.00", time: "2024-08-17 06:30:00" },
      { avatar: "/assets/pro2.png", nickname: "924***012", gameName: "Money Coming", wins: 18.30, bonus: "70.00", time: "2024-08-17 06:35:00" },
      { avatar: "/assets/pro3.png", nickname: "925***123", gameName: "Mines", wins: 11.90, bonus: "45.00", time: "2024-08-17 06:40:00" },
      { avatar: "/assets/pro4.png", nickname: "926***234", gameName: "Money Coming", wins: 20.25, bonus: "90.00", time: "2024-08-17 06:45:00" },
      { avatar: "/assets/pro1.png", nickname: "927***345", gameName: "Mines", wins: 9.50, bonus: "35.00", time: "2024-08-17 06:50:00" },
      { avatar: "/assets/pro2.png", nickname: "928***456", gameName: "Money Coming", wins: 16.75, bonus: "65.00", time: "2024-08-17 06:55:00" },
      { avatar: "/assets/pro3.png", nickname: "929***567", gameName: "Mines", wins: 10.25, bonus: "55.00", time: "2024-08-17 07:00:00" },
      { avatar: "/assets/pro4.png", nickname: "930***678", gameName: "Money Coming", wins: 22.50, bonus: "95.00", time: "2024-08-17 07:05:00" },
      { avatar: "/assets/pro2.png", nickname: "931***789", gameName: "Mines", wins: 13.40, bonus: "50.00", time: "2024-08-17 07:10:00" },
      { avatar: "/assets/pro3.png", nickname: "932***890", gameName: "Money Coming", wins: 23.75, bonus: "100.00", time: "2024-08-17 07:15:00" },
      { avatar: "/assets/pro4.png", nickname: "933***901", gameName: "Mines", wins: 14.80, bonus: "65.00", time: "2024-08-17 07:20:00" },
      { avatar: "/assets/pro1.png", nickname: "934***012", gameName: "Money Coming", wins: 21.00, bonus: "85.00", time: "2024-08-17 07:25:00" },
      { avatar: "/assets/pro2.png", nickname: "935***123", gameName: "Mines", wins: 10.75, bonus: "45.00", time: "2024-08-17 07:30:00" },
      { avatar: "/assets/pro3.png", nickname: "936***234", gameName: "Money Coming", wins: 19.10, bonus: "75.00", time: "2024-08-17 07:35:00" },
      { avatar: "/assets/pro4.png", nickname: "937***345", gameName: "Mines", wins: 16.25, bonus: "55.00", time: "2024-08-17 07:40:00" },
      { avatar: "/assets/pro1.png", nickname: "938***456", gameName: "Money Coming", wins: 24.00, bonus: "95.00", time: "2024-08-17 07:45:00" },
      { avatar: "/assets/pro2.png", nickname: "939***567", gameName: "Mines", wins: 12.60, bonus: "50.00", time: "2024-08-17 07:50:00" },
      { avatar: "/assets/pro3.png", nickname: "940***678", gameName: "Money Coming", wins: 17.85, bonus: "70.00", time: "2024-08-17 07:55:00" }
    ];
    

  return (
    <div>
      <Mobile>
        <Box sx={{ bgcolor: '#242424', minHeight: '100vh' }}>
          <AppBar position="static" sx={{ bgcolor: '#3f3f3f', color: 'white' }} elevation={0}>
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/superjackpot')}>
                <ArrowBackIosOutlinedIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                Winning star
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ mt: 2 }}>
            {winningItems.map((item, index) => (
              <WinningItem key={index} {...item} />
            ))}
          </Box>
        </Box>
        <br/>
        <br/>
        <br/>
      </Mobile>
    </div>
  );
};

export default WinningStar;