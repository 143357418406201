import AdminPanel from './Admin';
import PlayersSalaryMain from './PlayersSalaryMain';


const PlayersSalary = ({}) => {
  return (
    <AdminPanel>
      <PlayersSalaryMain />
    </AdminPanel>
  );
};

export default PlayersSalary;