import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container,SvgIcon } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Mobile from './Mobile';

const fakeData = [
  { days: 1, accumulated: '₹200.00', bonus: '₹5.00' },
  { days: 2, accumulated: '₹1,000.00', bonus: '₹18.00' },
  { days: 3, accumulated: '₹3,000.00', bonus: '₹100.00' },
  { days: 4, accumulated: '₹10,000.00', bonus: '₹200.00' },
  { days: 5, accumulated: '₹20,000.00', bonus: '₹400.00' },
  { days: 6, accumulated: '₹100,000.00', bonus: '₹3,000.00' },
  { days: 7, accumulated: '₹200,000.00', bonus: '₹7,000.00' },
  
];

const rules = [
  'The higher the number of consecutive login days, the more rewards you get, up to 7 consecutive days',
  'During the activity, please check once a day',
  'Players with no deposit history cannot claim the bonus',
  'Deposit requirements must be met from day one',
  'The platform reserves the right to final interpretation of this activity',
  'When you encounter problems, please contact customer service',
];

const RhombusIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24" sx={{ width: '12px', height: '12px',color:"#d9ac4e",marginBottom:"10px",marginRight:"5px" }}> {/* Reduced icon size */}
    <path d="M12 2L22 12L12 22L2 12L12 2Z" />
  </SvgIcon>
);
const GameRules = () => {
  return (
    <div><Mobile>
    <Container disableGutters maxWidth="xs" sx={{ bgcolor: '#242424', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{  bgcolor: '#3f3f3f', padding: '8px 10px', display: 'flex', alignItems: 'center', color: 'white' }}>
        <ChevronLeftIcon sx={{ fontSize: 30, cursor:"pointer" }} />
        <Typography variant="h6" sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '18px'
        }}>
          Game Rules
        </Typography>
      </Box>

      <Box sx={{p:2}}>
      <TableContainer
  component={Paper}
  sx={{
    mt: 2,
    border: 'none',
    borderRadius: '`10px', // Smooth rounded corners
    overflow: 'hidden',
    backgroundColor:"#242424" // Ensures no white corners
  }}
  elevation={0}
>
  <Table sx={{ border: 'none' }}>
    <TableHead>
      <TableRow sx={{ backgroundColor: '#3a3947', border: 'none' }}>
        <TableCell align="center" sx={{ color: '#fff', border: 'none', padding: '4px 8px' }}>
          Continuous attendance
        </TableCell>
        <TableCell align="center" sx={{ color: '#fff', border: 'none', padding: '4px 8px' }}>
          Accumulated amount
        </TableCell>
        <TableCell align="center" sx={{ color: '#fff', border: 'none', padding: '4px 8px' }}>
          Attendance bonus
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {fakeData.map((row) => (
        <TableRow
          key={row.days}
          sx={{
            backgroundColor: row.days % 2 === 0 ? '#3a3947' : '#333332',
            border: 'none',
          }}
        >
          <TableCell
            align="center"
            sx={{ border: 'none', color: '#a8a5a1' }}
          >
            {row.days}
          </TableCell>
          <TableCell
            align="center"
            sx={{ border: 'none', color: '#a8a5a1' }}
          >
            {row.accumulated}
          </TableCell>
          <TableCell
            align="center"
            sx={{ border: 'none', color: '#a8a5a1' }}
          >
            {row.bonus}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

<Box
    sx={{
      marginTop: 2,
      backgroundColor: '#333332', // Container background color
      padding: '16px',
      borderRadius: '8px', // Main container rounded corners
    }}
  >
    {/* Rule Header with curvy bottom */}
    <Box
      sx={{
        backgroundColor: '#3a3947', // Dark background for header
        padding: '8px 2px',
        borderRadius: '0 0 16px 16px', // Curvy bottom corners
        color: 'white',
        textAlign: 'center', // Center the text
      }}
    >
      <Typography variant="h6" sx={{ color: 'white' }}>
        Rules
      </Typography>
    </Box>

    {/* Content Box */}
    <Box component="ul" sx={{ padding: '16px', listStyle: 'none', marginTop: '8px' }}>
    {rules.map((rule, index) => (
      <li key={index} style={{ marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
        {/* Correct color and vertical alignment */}
        <RhombusIcon sx={{ color: '#d9ac4e', marginRight: '8px', marginTop: '0px' }} /> {/* Removed marginTop adjustment */}
        <Typography variant="body2" sx={{ color: '#a8a5a1', fontSize: '0.85rem', lineHeight: '1.2' }}> {/* Reduced font size and line-height */}
          {rule}
        </Typography>
      </li>
    ))}
  </Box>
  </Box>
      </Box>

    
    </Container>
    </Mobile></div>
  );
};

export default GameRules;