import React from "react";
import BottomNavigationArea from "../Components/BottomNavigation";
import WalletMain from "../Components/WalletMain";
const Wallet = () => {
  return (
    <div>
      <WalletMain>
        <BottomNavigationArea />
      </WalletMain>
    </div>
  );
};

export default Wallet;
