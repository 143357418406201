import { Box, Typography, TextField, Button, Container } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Customer from "../public/Customer .png";
import Mobile from "./Mobile";
import { useNavigate } from "react-router-dom";

const FeedbackPage = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <Mobile>
      <Container
        disableGutters
        maxWidth="xs"
        sx={{
          bgcolor: "#242424",
          height: "100vh",
          maxWidth: "100vw", // Set the maxWidth to 100vw to handle viewport width
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            bgcolor: "#3f3f3f",
            padding: "8px 10px",
            display: "flex",
            alignItems: "center",
            color: "white",
          }}
        >
          <ChevronLeftIcon
            sx={{ fontSize: 30, cursor: "pointer" }}
            onClick={handleBackClick}
          />

          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
          >
            Feedback
          </Typography>
        </Box>

        <Box sx={{ p: 2, flexGrow: 1 }}>
          <TextField
            fullWidth
            multiline
            rows={14}
            placeholder="Welcome to feedback, please give feedback - please describe the problem in detail when providing feedback, preferably attach a screenshot of the problem you encountered, we will immediately process your feedback!"
            variant="outlined"
            InputProps={{
              sx: {
                bgcolor: "#333332",
                borderRadius: 2,
                textAlign: "center",
                alignItems: "center",
                color: "#f5f3f0", // Input value color
                "&::placeholder": {
                  color: "#f5f3f0", // Placeholder color
                },
                "& textarea": {
                  textAlign: "center",
                },
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: "#f5f3f0", // Placeholder color
                "&.Mui-focused": {
                  color: "#f5f3f0", // Keeps placeholder color when focused
                },
              },
            }}
            sx={{ mb: 4 }}
          />

          <Box sx={{ textAlign: "center", mb: 2, color: "#f5f3f0" }}>
            <Typography variant="body1">Send helpful feedback</Typography>
            <Typography variant="body1">
              Chance to win Mystery Rewards
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Box
              component="img"
              src={Customer}
              alt="Feedback rewards"
              sx={{ width: "50%", height: "auto" }}
            />
          </Box>

          <Button
  fullWidth
  variant="contained"
  sx={{
    background: "linear-gradient(135deg, #f4db93, #c69643)", // Linear gradient background
    color: "#8f5206",
    borderRadius: "50px",
    border: "none",
    boxShadow: "none",
    padding: "10px 20px",
    "&:hover": {
      background: "linear-gradient(135deg, #f4db93, #c69643)", // Slightly darker gradient on hover
      border: "none",
    },
    "&:focus": {
      outline: "none",
      border: "none",
    },
    "&:active": {
      background: "linear-gradient(135deg, #e6ca84, #b5853e)", // Active state gradient
      border: "none",
      outline: "none",
    },
  }}
>
  Submit
</Button>

        </Box>
      </Container>
    </Mobile>
  );
};

export default FeedbackPage;