import AdminPanel from './Admin';
import GamesContent from './GamesContent';


const WinGo = ({}) => {
  return (
    <AdminPanel>
      <GamesContent />
    </AdminPanel>
  );
};

export default WinGo;