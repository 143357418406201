import AdminPanel from './Admin';
import SupportMain from './SupportMain';


const Support = ({}) => {
  return (
    <AdminPanel>
      <SupportMain />
    </AdminPanel>
  );
};

export default Support;