import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import SmsIcon from "@mui/icons-material/Sms";
import DownloadIcon from "@mui/icons-material/Download";
import { Tabs, AppBar, Toolbar } from "@mui/material";
import axios from "axios";
import Mobile from "./Mobile";
import { styled, useTheme } from "@mui/material/styles";
import MailIcon from "@mui/icons-material/Mail";
import SyncIcon from "@mui/icons-material/Sync";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Games from "../Components/Games";
import DepositModal from "../Pages/depositModal"; // Adjust the path as needed
import NotificationModal from "../Components/NotificationPopup";
import {
  Paper,
  Typography,
  Button,
  Grid,
  Box,
  List,
  ListItem,
  Container,
  useMediaQuery,
  Avatar,
} from "@mui/material";
import { Whatshot } from "@mui/icons-material";
import Stage from "./Stage";
import { CSSTransition } from "react-transition-group";

const Tab = styled(Container)(({ theme }) => ({
  textAlign: "center",
  borderRadius: "15px",
}));

const Header = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  position: "relative",
  paddingLeft: "10px",
  paddingRight: "10px",
}));
const ImageWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "auto",
}));

const UserInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  background: "linear-gradient(to top,#fbc02d, #ffecb3)",
  borderRadius: "10px",
  marginTop: theme.spacing(1),
  justifyContent: "space-between",
}));

const win = [
  {
    txt: "Mem***GII",
    image: "/assets/Pro1.png",
    amount: "₹112.00",
    image1: "/assets/card1.png",
  },
  {
    txt: "Mem***WFU",
    image: "/assets/Pro2.png",
    amount: "₹1,000.00",
    image1: "/assets/card2.png",
  },
  {
    txt: "Mem***ZJQ",
    image: "/assets/Pro3.png",
    amount: "₹482.00",
    image1: "/assets/card4.png",
  },
  {
    txt: "Mem***DYK",
    image: "/assets/Pro4.png",
    amount: "₹400.00",
    image1: "/assets/card5.png",
  },
  {
    txt: "Mem***LSU",
    image: "/assets/Pro5.png",
    amount: "₹800.00",
    image1: "/assets/card6.png",
  },
];

const textArray = [
  "Welcome to the Lucky Wingo Games! Greetings, Gamers and Enthusiasts!",
  "The Lucky Wingo Games are here to provide excitement and fun.",
  "For your convenience and account safety, please ensure",
  "you fill in the genuine mobile number registered with your bank.",
  "Thank you for your cooperation and enjoy the games!",
];

const Home = ({ children }) => {
  const [winners, setWinners] = useState(win);
  const [index, setIndex] = React.useState(0);
  const [inProp, setInProp] = React.useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const lastWinner = winners[winners.length - 1];
  const otherWinners = winners.slice(0, -1);

  useEffect(() => {
    const interval = setInterval(() => {
      setWinners((prevWinners) => {
        const lastWinner = prevWinners[prevWinners.length - 1];
        const newWinners = [lastWinner, ...prevWinners.slice(0, -1)];
        return newWinners;
      });
    }, 2000); // Adjust the timing as needed
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", setVh);
    setVh();

    return () => window.removeEventListener("resize", setVh);
  }, []);

  const navigate = useNavigate();
  React.useEffect(() => {
    const timer = setInterval(() => {
      setInProp(false);

      setTimeout(() => {
        setIndex((oldIndex) => {
          return (oldIndex + 1) % textArray.length;
        });
        setInProp(true);
      }, 500); // This should be equal to the exit duration below
    }, 3000); // Duration between changing texts

    return () => clearInterval(timer);
  }, []);
  const handleTabClick = async (path) => {
    navigate(path);
  };

  const handleClick = () => {
    navigate('/messages');
  };

  const imageurl = [
    {
      id: 1,
      image: "/assets/Banner7.jpg",
      text: "Second Image",
    },
    {
      id: 2,
      image: "/assets/Banner8.jpg",
      text: "Third Image",
    },
    {
      id: 3,
      image: "/assets/Banner9.jpg",
      text: "Third Image",
    },
    {
      id: 4,
      image: "/assets/Banner10.jpg",
      text: "Third Image",
    },
    {
      id: 5,
      image: "/assets/Banner11.jpg",
      text: "Third Image",
    },
    {
      id: 6,
      image: "/assets/Banner6.jpg",
      text: "Second Image",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageurl.length);
    }, 2000);
    // Change image every 2 seconds
    return () => clearInterval(interval);
  }, [imageurl.length]);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % imageurl.length);
    }, 4000);
    // Change image every 2 seconds
    return () => clearInterval(interval);
  }, [imageurl.length]);

  const handleAviatorClick = async () => {
    try {
      console.log("Clicked");
      const response = await axios.post(
        "https://luckywingo.tech/redirect-to-second-website",
        {},
        {
          withCredentials: true,
        }
      );
      console.log("response--->", response);
      // Assuming response.ok is equivalent to a successful response (status in 200-299 range)
      if (response.status) {
        console.log("status-->", response.status);
        // Open the redirectUrl in a new window/tab
        window.open(response.data.redirectUrl, "_blank");
      } else {
        console.error("Registration/Login failed:", response.data.error);
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [activeTab, setActiveTab] = useState(0); // Add this line
  const StyledPaper = styled(Paper)(({ theme }) => ({
    transition: "transform 0.5s ease-in-out",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }));
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `https://drive.google.com/file/d/1rVo4UBpknQs6UGO57trVxBOmGHzhrOeO/view?usp=sharing`; // Change this to the actual path of the APK file on your server
    link.download = "https://drive.google.com/file/d/1rVo4UBpknQs6UGO57trVxBOmGHzhrOeO/view?usp=sharing";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 2 seconds

    // Cleanup function to clear the timeout if the component unmounts before 2 seconds
    return () => clearTimeout(timer);
  }, []);

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    // Set a timeout to show the modal after 2 seconds
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 2000);
    // Clean up the timeout if the component unmounts to avoid memory leaks
    return () => clearTimeout(timer);
  }, []);
  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isDepositModalOpen, setDepositModalOpen] = useState(false);
  useEffect(() => {
    // Automatically show NotificationModal 2 seconds after component mounts
    const timer = setTimeout(() => {
      setNotificationModalOpen(true);
    }, 2000); // 2 second delay
    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);
  const handleNotificationClose = () => {
    setNotificationModalOpen(false);
    // Open DepositModal when NotificationModal is closed
    setTimeout(() => setDepositModalOpen(true), 300); // Delay to ensure modal transition
  };
  const handleDepositModalClose = () => {
    setDepositModalOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <Mobile>
        <NotificationModal
          open={isNotificationModalOpen}
          onClose={handleNotificationClose}
        />
        <DepositModal
          open={isDepositModalOpen}
          onClose={handleDepositModalClose}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
            }}
          ></div>
        )}
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
          sx={{
            backgroundColor: "#242424", // Base background color
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#242424",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#242424",
            },
          }}
        >
          <Box flexGrow={1} sx={{ backgroundColor: "#242424" }}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#3f3f3f",
                padding: "2px 16px",
                color: "white",
              }}
            >
              {/* Left Section: Logo, Flag, and Text */}
              <Grid item xs={8}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  {/* Logo */}
                  <img
                    src="assets/greenlogo.png"
                    alt="logo"
                    style={{ width: "150px", height: "45px" }}
                  />
                  {/* Flag and Welcome Text */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="assets/flag.png"
                      alt="flag"
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "#d9ac4f",
                        fontWeight: "bold",
                        marginRight: "1rem",
                      }}
                    >
                      Welcome to Lucky Wingo Game
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              {/* Right Section: Buttons */}
              <Grid item xs={4} textAlign="right">
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Button
                    variant="contained"
                    sx={{
                      background: "linear-gradient(to bottom,#f9e39c, #c69643)",
                      color: "#3f3f3f",
                      textTransform: "none",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      padding: "1px 9px",
                      borderRadius: "8px",
                    }}
                    startIcon={<DownloadIcon />} // Icon for download button
                    onClick={handleDownload}
                  >
                    Download APP
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      background: "linear-gradient(to bottom,#f9e39c, #c69643)",
                      color: "#3f3f3f",
                      textTransform: "none",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      padding: "1px 9px",
                      borderRadius: "8px",
                    }}
                    startIcon={<HeadsetMicIcon />} // Icon for customer service button
                    onClick={() => navigate("/customer-service")}
                  >
                    Customer Service
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {/* //content */}

            <Header>
              <ImageWrapper>
                <img
                  src={imageurl[currentImageIndex].image}
                  alt={`Banner ${currentImageIndex + 1}`}
                  style={{
                    borderRadius: 10,
                    width: "100%",
                    height: "180px",
                  }}
                />
              </ImageWrapper>
            </Header> 

            <Grid
              item
              sx={{
                backgroundColor: "#242424",
                margin: "10px",
                borderRadius: "20px",
                padding: "3px 2px", // Adjust padding to make room for the button and text
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden", // Ensure content stays within the box
              }}
            >
              <IconButton>
                <VolumeUpIcon sx={{ color: "#d9ac4f" }} />
              </IconButton>

              <Box sx={{ flex: 1, overflow: "hidden", padding: "0 10px" }}>
                <CSSTransition
                  in={inProp}
                  timeout={500}
                  classNames="message"
                  unmountOnExit
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: "12.8px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      textAlign: "left",
                      overflow: "hidden",
                      WebkitLineClamp: 2, // Limits the text to 2 lines
                      lineClamp: 2, // Fallback for non-WebKit browsers
                      textOverflow: "ellipsis", // Adds "..." at the end of overflowed text
                    }}
                  >
                    {textArray[index]}
                  </Typography>
                </CSSTransition>
              </Box>

              <Button
      onClick={handleClick}
      variant="contained"
      sx={{
        background: "linear-gradient(to right,#f9e39c, #c69643)",
        "&:hover": {
          background: "linear-gradient(to right,#f9e39c, #c69643)",
        },
        borderRadius: "5px",
        fontSize: "11px",
        textTransform: "initial",
        padding: "4px 12px",
        color: "#333",
      }}
    >
      Details
    </Button>
            </Grid>

            <Box
              sx={{ flexGrow: 1, maxWidth: 600, margin: "auto", padding: 1 }}
            >
              <UserInfo>
                <Typography
                  color="black"
                  sx={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <PersonIcon />
                  MemberNNGLYQF5-₹0.00
                  <SyncIcon />
                </Typography>
                <IconButton onClick={handleClick}>
      <MailIcon style={{color: "black"}}/>
    </IconButton>
              </UserInfo>
            </Box>

            {/* 
            {/* All game cards section */}

            <Games />
            {/* Winning Information */}

            <Box sx={{ maxWidth: 600, margin: "auto", padding: 1 }}>
              <Typography
                variant="h5"
                color="#d9ac4e"
                fontFamily=" bahnschrift"
                fontWeight="bold"
                textAlign="left"
              >
                | Winning Information
              </Typography>
            </Box>
            <Box sx={{ mt: 2, margin: 1 }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "5px",
                  marginRight: "5px",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 1, md: 2 }}
                  columns={{ xs: 1, sm: 1, md: 1 }}
                >
                  {winners.map((item, index) => (
                    <Grid item xs={12} key={index} mb={0}>
                      <StyledPaper
                        sx={{ backgroundColor: "#333332", padding: 1 }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <img
                              src={item.image}
                              alt=""
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                border: "0.5px solid white",
                                margin: 2,
                              }}
                            />
                            <Typography
                              sx={{ color: "#a7a9af", fontSize: "0.7rem" }}
                            >
                              {item.txt}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              minWidth: "200px",
                            }}
                          >
                            <img
                              src={item.image1}
                              alt=""
                              style={{
                                width: "70px",
                                height: "45px",
                                borderRadius: "10px",
                                background:
                                  "linear-gradient(to right, #ff8a80, #ffccbc)",
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "left",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fae59f",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Receive ₹{item.amount}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#a8a5a1",
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Winning Amount
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </StyledPaper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ maxWidth: 600, margin: "auto", padding: 1 }}>
              <Typography
                variant="h5"
                color="#d9ac4e"
                fontFamily=" bahnschrift"
                fontWeight="bold"
                textAlign="left"
              >
                | Today's Earning Chart
              </Typography>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  marginLeft: "5px",
                  marginRight: "5px",
                  marginTop: "60px",
                  marginBottom: "80px",
                }}
              >
                <Stage />
              </Grid>
            </Box>

            <br />
            {/* content end */}
          </Box>

          {children}
        </Box>
      </Mobile>
    </div>
  );
};

export default Home;
