import React from "react";
import { Box, Button } from "@mui/material";

const LevelHeader = ({ onCancel, onConfirm }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "7px 16px",
      backgroundColor: "#333332",
      color: "#a8a5a1",
    }}
  >
    <Button
      onClick={onCancel}
      sx={{ color: "#a8a5a1", textTransform: "none" }}
    >
      Cancel
    </Button>
    <Button
  onClick={onConfirm}
  sx={{
    color: "#d9ac4e",
    textTransform: "none",
    fontWeight: "normal",
  }}
>
  Confirm Level
</Button>

  </Box>
);

export default LevelHeader;