import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Switch, FormControlLabel } from "@mui/material";
import axios from "axios";
import { domain } from "../../Components/config";

const Dashboard = () => {
  const [data, setData] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [todayrecharge, setTodayrecharge] = useState(0);
  const [todayWithdrawal, setTodayWithdrawal] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [pendingRecharge, setPendingRecharge] = useState(0);
  const [successRecharge, setSuccessRecharge] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [withdrawalRequests, setWithdrawalRequests] = useState(0);
  const [pendingComplaints, setPendingComplaints] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          axios.get(`${domain}/todays-registrations`, { withCredentials: true }),
          axios.get(`${domain}/admin/normal-users-wallet-summary`, { withCredentials: true }),
          axios.get(`${domain}/recharge-today`, { withCredentials: true }),
          axios.get(`${domain}/total-withdraw-amount-last-24-hours`, { withCredentials: true }),
          axios.get(`${domain}/total-registrations`, { withCredentials: true }),
          axios.get(`${domain}/pending-recharge`, { withCredentials: true }),
          axios.get(`${domain}/success-recharge`, { withCredentials: true }),
          axios.get(`${domain}/total-withdrawl-amount`, { withCredentials: true }),
          axios.get(`${domain}/maintenance-mode`, { withCredentials: true }),
          axios.get(`${domain}/pending-withdrawals-count`, { withCredentials: true }),
          axios.get(`${domain}/pending-complaints-count`, { withCredentials: true }),
        ]);

        setData(responses[0].data.countOfDailyUsers || 0);
        setUserBalance(responses[1].data.totalWalletAmount || 0);
        setTodayrecharge(responses[2].data.totalRechargeAmount || 0);
        setTodayWithdrawal(responses[3].data.totalWithdrawAmount || 0);
        setTotalUser(responses[4].data.count || 0);
        setPendingRecharge(responses[5].data.pendingAmount || 0);
        setSuccessRecharge(responses[6].data.successAmount || 0);
        setTotalWithdrawal(responses[7].data.completeWithdrawAmount || 0);
        setMaintenanceMode(responses[8].data.maintenanceMode || false);
        setWithdrawalRequests(responses[9].data.pendingWithdrawCount || 0);
        setPendingComplaints(responses[10].data.pendingComplaintsCount || 0);

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        // Set default values in case of error
        setData(0);
        setUserBalance(0);
        setTodayrecharge(0);
        setTodayWithdrawal(0);
        setTotalUser(0);
        setPendingRecharge(0);
        setSuccessRecharge(0);
        setTotalWithdrawal(0);
        setMaintenanceMode(false);
        setWithdrawalRequests(0);
        setPendingComplaints(0);
      }
    };

    fetchData();
  }, []);

  const handleToggleMaintenanceMode = () => {
    axios.post(`${domain}/maintenance-mode`, {}, { withCredentials: true })
      .then(res => setMaintenanceMode(res.data.maintenanceMode || false))
      .catch(err => console.log("Error while toggling maintenance mode:", err));
  };

  const dat = [
    { heading: "Today User Join", value: data },
    { heading: "Today's Recharge", value: todayrecharge },
    { heading: "Today's Withdrawal", value: todayWithdrawal },
    { heading: "User Balance", value: userBalance },
    { heading: "Total User", value: totalUser },
    { heading: "Pending Recharges", value: pendingRecharge },
    { heading: "Success Recharge", value: successRecharge },
    { heading: "Total Withdrawal", value: totalWithdrawal },
    { heading: "Withdrawal Requests", value: withdrawalRequests },
    { heading: "Website Mode", value: maintenanceMode ? "On" : "Off" },
    { heading: "Pending Complaints", value: pendingComplaints },
  ];

  return (
    <div style={{ minHeight: "85vh", padding: "20px" }}>
      <Grid container spacing={4}>
        {dat.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Paper
              style={{
                padding: "25px",
                textAlign: "left",
                backgroundColor: "#4d4d4c",
                color: "#ffffff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" gutterBottom>{item.heading}</Typography>
              <Typography variant="h6">{item.value}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {/* <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Typography variant="h6">Maintenance Mode</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={maintenanceMode}
              onChange={handleToggleMaintenanceMode}
              color="primary"
            />
          }
          label={maintenanceMode ? "On" : "Off"}
        />
      </div> */}
    </div>
  );
};

export default Dashboard;
