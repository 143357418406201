import React, { useEffect, useState } from "react";
import axios from "axios";
import { domain, wssdomain } from "../../Components/config";
import {
  Grid,
  Typography,
  Button,
  MenuItem,
  Box,
  Card,
  CardContent,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Styled components for modern UI
const CustomBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: "12px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
}));

const CustomCard = styled(Card)(({ theme }) => ({
  borderRadius: "12px",
  boxShadow: theme.shadows[5],
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(3),
  minHeight: "150px",
  maxWidth: "250px",
  margin: "auto",
  height: "100%", // Ensure cards take full height in Grid cell
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const CardValue = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "bold",
}));

const FormBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

const DiceFieldContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between", // Adjust to space-between for alignment
  gap: theme.spacing(2),
  width: "100%", // Ensure it uses full width
}));

const DiceField = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
}));

const HeaderRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: theme.spacing(4),
}));

const SizeParityContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%", // Ensure it uses full width
}));

const K3GamesContent = () => {
  const [totalBets, setTotalBets] = useState({
    totalSum: 0,
    twoSameOneDifferent: 0,
    threeSame: 0,
    threeDifferentNumbers: 0,
  });
  const [selectedTimer, setSelectedTimer] = useState("1min");
  const [currentPeriodId, setCurrentPeriodId] = useState(null);
  const [dice1, setDice1] = useState("");
  const [dice2, setDice2] = useState("");
  const [dice3, setDice3] = useState("");
  const [size, setSize] = useState("");
  const [parity, setParity] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [selectedTimer]);

  useEffect(() => {
    const socket = new WebSocket(wssdomain);

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (message.timers && message.timers[selectedTimer]) {
          const newPeriodId = message.timers[selectedTimer].periodId;
          if (currentPeriodId !== newPeriodId) {
            setCurrentPeriodId(newPeriodId);
            window.location.reload(); // Refresh the page when periodId changes
          }
        }

        if (message.totalBets) {
          setTotalBets(message.totalBets);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      // Suppress error in the UI, only log to console
    };

    return () => {
      socket.close();
    };
  }, [selectedTimer, currentPeriodId]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${domain}/latest-k3-bet-sums`, {
        params: { timer: selectedTimer },
        withCredentials: true,
      });

      const { periodId, totals } = response.data;

      setCurrentPeriodId(periodId);
      setTotalBets({
        totalSum: totals.totalSum,
        twoSameOneDifferent: totals.twoSameOneDifferent,
        threeSame: totals.threeSame,
        threeDifferentNumbers: totals.threeDifferentNumbers,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimerChange = (event) => {
    setSelectedTimer(event.target.value);
  };

  const handleDiceChange = (event) => {
    const { name, value } = event.target;
    if (name === "dice1") setDice1(value);
    if (name === "dice2") setDice2(value);
    if (name === "dice3") setDice3(value);
  };

  const calculateSize = (dice1, dice2, dice3) => {
    const total = Number(dice1) + Number(dice2) + Number(dice3);
    return total <= 10 ? "Small" : "Big";
  };

  const calculateParity = (dice1, dice2, dice3) => {
    const total = Number(dice1) + Number(dice2) + Number(dice3);
    return total % 2 === 0 ? "Even" : "Odd";
  };

  useEffect(() => {
    if (dice1 && dice2 && dice3) {
      setSize(calculateSize(dice1, dice2, dice3));
      setParity(calculateParity(dice1, dice2, dice3));
    }
  }, [dice1, dice2, dice3]);

  const handleSubmit = async () => {
    const totalSum = Number(dice1) + Number(dice2) + Number(dice3);
    const diceOutcome = [Number(dice1), Number(dice2), Number(dice3)];

    const postData = {
      timerName: selectedTimer,
      periodId: currentPeriodId,
      totalSum,
      size,
      parity,
      diceOutcome,
    };

    try {
      setIsLoading(true);
      setError(null);
      await axios.post(`${domain}/k3/manual-result`, postData, {
        withCredentials: true,
      });
      alert("Manual result set successfully!");
      // Clear the fields
      setDice1("");
      setDice2("");
      setDice3("");
      setSize("");
      setParity("");
    } catch (error) {
      console.error("Error setting manual result:", error);
      setError("Failed to set manual result. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomBox>
      <HeaderRow>
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel>Timer</InputLabel>
          <Select
            value={selectedTimer}
            onChange={handleTimerChange}
            label="Timer"
          >
            <MenuItem value="1min">1min</MenuItem>
            <MenuItem value="3min">3min</MenuItem>
            <MenuItem value="5min">5min</MenuItem>
            <MenuItem value="10min">10min</MenuItem>
          </Select>
        </FormControl>
        <Typography variant="h6">
          Current Period ID: {currentPeriodId || "Loading..."}
        </Typography>
      </HeaderRow>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomCard>
            <CardContent>
              <CardTitle>Total Sum</CardTitle>
              <CardValue>{totalBets.totalSum}</CardValue>
            </CardContent>
          </CustomCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomCard>
            <CardContent>
              <CardTitle>Two Same One Different</CardTitle>
              <CardValue>{totalBets.twoSameOneDifferent}</CardValue>
            </CardContent>
          </CustomCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomCard>
            <CardContent>
              <CardTitle>Three Same</CardTitle>
              <CardValue>{totalBets.threeSame}</CardValue>
            </CardContent>
          </CustomCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomCard>
            <CardContent>
              <CardTitle>Three Different Numbers</CardTitle>
              <CardValue>{totalBets.threeDifferentNumbers}</CardValue>
            </CardContent>
          </CustomCard>
        </Grid>
      </Grid>
      <FormBox>
        <DiceFieldContainer>
          <DiceField variant="outlined">
            <InputLabel>Dice 1</InputLabel>
            <Select value={dice1} onChange={handleDiceChange} name="dice1">
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </DiceField>
          <DiceField variant="outlined">
            <InputLabel>Dice 2</InputLabel>
            <Select value={dice2} onChange={handleDiceChange} name="dice2">
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </DiceField>
          <DiceField variant="outlined">
            <InputLabel>Dice 3</InputLabel>
            <Select value={dice3} onChange={handleDiceChange} name="dice3">
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </DiceField>
        </DiceFieldContainer>
        <SizeParityContainer>
          <Typography>Size: {size}</Typography>
          <Typography>Parity: {parity}</Typography>
        </SizeParityContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Set Result"}
        </Button>
        {error && <Alert severity="error">{error}</Alert>}
      </FormBox>
    </CustomBox>
  );
};

export default K3GamesContent;
