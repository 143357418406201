import AdminPanel from './Admin';
import NotificationMain from './NotificationMain';


const Members = ({}) => {
  return (
    <AdminPanel>
      <NotificationMain />
    </AdminPanel>
  );
};

export default Members;