import React, { useState, useCallback } from "react";
import {
  Grid,
  Button,
  IconButton,
  Drawer,
  Tabs,
  Tab,
  Box,
  TextField,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  MenuItem 
} from "@mui/material";
import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  Translate as TranslateIcon,
  Flag as FlagIcon,
  Visibility,
  VisibilityOff,
  Email as EmailIcon,
  Lock as LockIcon,
  SupportAgent as SupportAgentIcon,
} from "@mui/icons-material";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { domain } from "../Components/config";
import PuzzleSlider from "../Components/PuzzleComponent";
import Mobile from "../Components/Mobile";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';


const countryCodes = [
  { code: "+1", country: "United States" },
  { code: "+44", country: "United Kingdom" },
  { code: "+91", country: "India" },
  { code: "+61", country: "Australia" },
  { code: "+81", country: "Japan" },
  { code: "+49", country: "Germany" },
  { code: "+86", country: "China" },
  { code: "+33", country: "France" },
  { code: "+39", country: "Italy" },
  { code: "+7", country: "Russia" },
  { code: "+55", country: "Brazil" },
  { code: "+27", country: "South Africa" },
  { code: "+34", country: "Spain" },
  { code: "+82", country: "South Korea" },
  { code: "+52", country: "Mexico" },
  { code: "+20", country: "Egypt" },
  { code: "+234", country: "Nigeria" },
  { code: "+62", country: "Indonesia" },
  { code: "+966", country: "Saudi Arabia" },
  { code: "+64", country: "New Zealand" }
];


const Login = () => {

  const [countryCode, setCountryCode] = useState("+91");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPuzzle, setShowPuzzle] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setOpenDrawer(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${domain}/login`, {
        mobile,
        password,
      });
      if (response.status === 200) {
        const isAdmin = response.data.user.accountType === "Admin";
        login(response.data.token, isAdmin);
        setShowPuzzle(true);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        alert(error.response.data.msg);
      } else {
        alert("An error occurred while logging in. Please try again");
      }
    }
  };

  const handlePuzzleSolved = useCallback(() => {
    setShowPuzzle(false);
    navigate("/");
  }, [navigate]);

  const handleRegister = () => {
    navigate("/register");
  };

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <Mobile>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          backgroundColor: "#3f3f3f",
          padding: "8px 16px",
          color: "black",
        }}
      >
        <Grid item xs={4} textAlign="left">
          <IconButton sx={{ color: "white" }} onClick={handleRedirect}>
            <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
          </IconButton>
        </Grid>
        <Grid item xs={4} textAlign="center">
          <img
            src="../../assets/luckywingo.png"
            alt="logo"
            style={{ width: "120px", height: "40px" }}
          />
        </Grid>
        <Grid item xs={4} textAlign="right">
          <IconButton onClick={() => setOpenDrawer(true)} color="inherit">
            <TranslateIcon sx={{ color: "white" }} />
            {selectedLanguage && (
              <>
                {selectedLanguage === "EN" && (
                  <FlagIcon
                    component="span"
                    fontSize="small"
                    sx={{ marginLeft: "4px" }}
                  />
                )}
                {selectedLanguage === "HN" && (
                  <FlagIcon
                    component="span"
                    fontSize="small"
                    sx={{ marginLeft: "4px" }}
                  />
                )}
                <span>{selectedLanguage}</span>
              </>
            )}
          </IconButton>
        </Grid>
      </Grid>

      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Button onClick={() => handleLanguageSelect("EN")}>
            <ReactCountryFlag countryCode="US" svg />
            EN
          </Button>
          <Button onClick={() => handleLanguageSelect("HN")}>
            <ReactCountryFlag countryCode="IN" svg />
            HN
          </Button>
        </Grid>
      </Drawer>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          backgroundColor: "#333332",
          padding: "16px",
          color: "white",
          minHeight: "fit-content",
        }}
        direction="column"
      >
        <Typography variant="h5">Login</Typography>
        <Typography variant="subtitle2">
          Please login with your phone number or email
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{
          backgroundColor: "#242424",
          padding: "16px",
          color: "white",
          minHeight: "fit-content",
        }}
      >
        <Grid item xs={12} sx={{ marginBottom: "50px" }}>
          <form onSubmit={handleEmailLogin}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: tabValue === 0 ? "#d9ac4f" : "grey",
                },
              }}
            >
              <Tab
                icon={
                  <PhoneIphoneIcon
                    style={{
                      color: tabValue === 0 ? "#d9ac4f" : "grey",
                    }}
                  />
                }
                label="phone number"
                style={{ color: tabValue === 0 ? "#d9ac4f" : "grey" }}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Box display="flex" alignItems="center" mt={2}>
                <PhoneIphoneIcon sx={{ color: "#d9ac4f" }} />
                <FormLabel sx={{ marginLeft: "10px", color: "#f5f3f0" }}>
                  Phone number
                </FormLabel>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {/* Country Code Field */}
      <TextField
        select
        label="Country Code"
        value={countryCode}
        onChange={(e) => setCountryCode(e.target.value)}
        sx={{
          width: "125px",
          backgroundColor: "#333332",
          border:"none",
          marginBottom:-1,
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent", // Ensure no border
              border: "none", // Ensure no border
            },
            "&:hover fieldset": {
              borderColor: "transparent", // Remove hover border
              border: "none", // Ensure no border
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent", // Remove focused border
              border: "none", // Ensure no border
            },
          },
          "& .MuiInputBase-input": {
            color: "#f5f3f0",
          },
          "& .MuiInputLabel-root": {
            color: "#f5f3f0",
          },
        }}
        InputLabelProps={{
          style: { color: "#a8a5a1" },
        }}
      >
        {countryCodes.map((item) => (
          <MenuItem key={item.code} value={item.code}>
            {item.code} {item.country}
          </MenuItem>
        ))}
      </TextField>
      
      {/* Phone Number Field */}
      <TextField
        label="Please enter the phone number"
        fullWidth
        variant="outlined"
        margin="normal"
        value={mobile}
        onChange={(e) => setMobile(e.target.value)}
        required
        sx={{
          backgroundColor: "#333332",
          borderRadius: "10px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#333332 !important",
            },
            "&:hover fieldset": {
              borderColor: "#333332 !important",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#27252e !important",
            },
          },
          "& .MuiInputBase-input": {
            color: "#f5f3f0",
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 100px #333332 inset",
              WebkitTextFillColor: "#f5f3f0",
              caretColor: "#f5f3f0",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#f5f3f0",
          },
        }}
        InputProps={{
          style: { borderRadius: "10px", color: "white" },
        }}
        InputLabelProps={{
          style: { color: "#a8a5a1" },
        }}
      />
    </Box>

            </TabPanel>
            <Box display="flex" alignItems="center" mt={2}>
              <LockIcon sx={{ color: "#d9ac4f" }} />
              <FormLabel sx={{ color: "#f5f3f0", marginLeft: "10px" }}>
                Password
              </FormLabel>
            </Box>
            <TextField
  label="Password"
  type={showPassword ? "text" : "password"}
  fullWidth
  variant="outlined"
  margin="normal"
  required
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  sx={{
    backgroundColor: "#333332",
    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#333332 !important", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#333332 !important", // Hover border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "#27252e !important", // Focused border color
      },
    },
    "& .MuiInputBase-input": {
      color: "#f5f3f0", // Input text color
      caretColor: "#f5f3f0", // Set caret (cursor) color to white
      "&:-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 100px #333332 inset", // Override background color for autofill
        WebkitTextFillColor: "#f5f3f0", // Autofill text color
        caretColor: "#f5f3f0", // Ensure cursor remains white during autofill
        borderRadius: "10px",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#f5f3f0", // Label color
    },
  }}
  InputProps={{
    style: { borderRadius: "10px", color: "black" },
    endAdornment: (
      <IconButton
        onClick={handleShowPassword}
        edge="end"
        sx={{ color: "#f5f3f0" }} // Icon color
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    ),
  }}
  InputLabelProps={{
    style: { color: "#a8a5a1" }, // Label color
  }}
/>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "2px 0 20px",
              }}
            >
              <RadioGroup row style={{ color: "#d9ac4f" }}>
                <FormControlLabel
                  value="remember"
                  control={<Radio style={{ color: "#d9ac4f" }} />}
                  label="Remember Password "
                  labelPlacement="end"
                  style={{ color: "#f5f3f0" }}
                />
              </RadioGroup>
            </Box>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              style={{
                marginBottom: "8px",
                backgroundColor: "#6f7381",
                borderRadius: "300px",
                fontWeight: "bold",
                color:"#a8a5a1"
              }}
            >
              Log in
            </Button>
            <Button
              onClick={handleRegister}
              variant="outlined"
              color="primary"
              fullWidth
              style={{
                borderRadius: "300px",
                borderColor: "#d9ac4f",
              }}
            >
              <span
                style={{
                  color: "#d9ac4f",
                  marginLeft: "3px",
                  fontWeight: "bold",
                }}
              >
                Register
              </span>
            </Button>
          </form>
        </Grid>
        <Grid
          xs={6}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ padding: "16px", marginTop: "3px" }}
        >
          <LockIcon
            style={{
              fontSize: 50,
              color: "#d9ac4f",
              marginBottom: "10px",
            }}
          />
          <Typography
            variant="subtitle1"
            style={{ color: "#f5f3f0", marginBottom: "150px" }}
          >
            Forgot password
          </Typography>
        </Grid>
        <Grid
          xs={6}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ padding: "16px" }}
        >
          <SupportAgentIcon style={{ fontSize: 60, color: "#d9ac4f" }} />
          <Typography
            variant="subtitle1"
            style={{ color: "#f5f3f0", marginBottom: "150px" }}
          >
            Customer Service
          </Typography>
        </Grid>
      </Grid>

      <Modal
        open={showPuzzle}
        onClose={() => setShowPuzzle(false)}
        aria-labelledby="puzzle-modal"
        aria-describedby="puzzle-to-complete-login"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "background.paper",
            boxShadow: 24,
            // p: 4,
            borderRadius: 2,
          }}
        >
          <PuzzleSlider onPuzzleSolved={handlePuzzleSolved} />
        </Box>
      </Modal>
    </Mobile>
  );
};

const TabPanel = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

export default Login;