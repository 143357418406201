import React from "react";
import AttendanceMain from "../Components/AttendanceMain";
import BottomNavigationArea from "../Components/BottomNavigation";

const Activity = () => {
  return (
    <div>
      <AttendanceMain>
        <BottomNavigationArea />
      </AttendanceMain>
    </div>
  );
};

export default Activity;
