import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import NoteIcon from "@mui/icons-material/Note";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import Mobile from "../Components/Mobile";
import { useNavigate } from "react-router-dom";
// Background with Image and Gradient
const ImageBackground = styled(Box)({
  position: "relative",
  backgroundImage: "linear-gradient(135deg, #ff8a65, #ff7043)", // Adjust gradient to match
  backgroundSize: "cover",
  backgroundPosition: "center",
  padding: "16px",
  zIndex: 1,

  color: "white",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  height: "150px", // Slightly smaller height for the image area

  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("../assets/invitation_bg.png")', // Image overlay
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    opacity: 1, // More prominent image overlay
    zIndex: 1,
  },
});

const GrayButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#494949",
  color: "white",
  "&:hover": {
    backgroundColor: "#5c5c5c",
  },
  textTransform: "none",
  justifyContent: "center",
  padding: "8px 16px",
  width: "48%",
  borderRadius: "8px", // Rounded button corners
  fontSize: "14px",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  fontSize: "20px", // Larger icon size
  color: "#bdbdbd", // Light gray color for the icons
}));

const InvitationBonus = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous URL
  };
  return (
    <Mobile>
      <Box
        sx={{
          bgcolor: "#3c3c3c",
          minHeight: "100vh",
          maxWidth: "sm",
          mx: "auto",
        }}
      >
        <AppBar position="static" sx={{ bgcolor: "#333" }}>
          <Toolbar>
            <Grid item xs={2} textAlign="left">
              <IconButton color="inherit" onClick={handleBackClick}>
                <ArrowBackIosOutlinedIcon sx={{ color: "white" }} />
              </IconButton>
            </Grid>
            <Typography
              variant="body1"
              sx={{ flexGrow: 1, fontWeight: "normal", fontSize: "18px" }}
            >
              Invitation Bonus
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Image background with linear gradient */}
        <ImageBackground>
          <Box sx={{ position: "relative", zIndex: 2, textAlign: "left" }}>
            <Typography
              variant="h6"
              gutterBottom
              fontWeight="bold"
              sx={{ fontSize: "18px" }}
            >
              Invite friends and deposit
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "14px" }}>
              Both parties can receive rewards
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "14px" }}>
              Invite friends to register and recharge
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "14px" }}>
              to receive rewards
            </Typography>
            <Typography variant="body2" sx={{ mt: 2, fontSize: "14px" }}>
              activity date:
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "14px", color: "white" }}
            >
              2024-05-03 - End date is not declared
            </Typography>
          </Box>
        </ImageBackground>

        <Box
          sx={{
            p: 1,
            margin: 5,
            display: "flex",
            justifyContent: "space-around",
            backgroundColor: "#4d4d4c",
            borderRadius: "16px",
            marginTop: "-3%",
            zIndex: 10,
            position: "relative",
            gap: 2,
          }}
        >
          {/* Invitation Reward Rules */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src="/assets/inviterule-7c5f5524.svg"
                alt="Invitation Reward Rules"
                sx={{ width: "40px", height: "40px" }}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{ color: "#D1D1D1", fontWeight: "500" }}
            >
              Invitation reward rules
            </Typography>
          </Box>

          {/* Invitation Record */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: "50px",
                height: "50px",
                borderRadius: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src="/assets/inviterecord-83990d9a.svg"
                alt="Invitation Record"
                sx={{ width: "40px", height: "40px" }}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{ color: "#D1D1D1", fontWeight: "500" }}
            >
              Invitation record
            </Typography>
          </Box>
        </Box>

        <Box sx={{ px: 2 }}>
          {/* Bonus and Close Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                size="small"
                sx={{
                  bgcolor: "#4caf50",
                  color: "white",
                  mr: 1,
                  textTransform: "none",
                  borderRadius: "6px",
                  padding: "6px 12px",
                  fontSize: "14px",
                  "&:hover": { bgcolor: "#45a049" },
                }}
              >
                Bonus 1
              </Button>
              <StyledIconButton size="small">
                <CloseIcon fontSize="small" />
              </StyledIconButton>
            </Box>
            <Typography
              variant="h6"
              color="#ffa726"
              fontWeight="bold"
              sx={{ fontSize: "16px" }}
            >
              ₹55.00
            </Typography>
          </Box>

          {/* Invitees and Recharge Info */}
          <Paper
            elevation={0}
            sx={{
              bgcolor: "#2e2e2e",
              p: 2,
              borderRadius: "8px",
              mb: 2,
              color: "white",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body2" color="#f5f3f0">
                Number of invitees
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ color: "white" }}
              >
                1
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body2" color="#f5f3f0">
                Recharge per person
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ color: "#ff7043" }}
              >
                ₹300.00
              </Typography>
            </Box>
          </Paper>

          {/* Progress Details */}
          <Paper
            sx={{
              bgcolor: "#424242",
              color: "white",
              p: 2,
              mb: 2,
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Typography variant="body1" sx={{ color: "#ffa726" }}>
                0 / 1
              </Typography>
              <Typography variant="body1" sx={{ color: "#ffa726" }}>
                0 / 1
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="caption" color="white">
                Number of invitees
              </Typography>
              <Typography variant="caption" color="white">
                Deposit number
              </Typography>
            </Box>
          </Paper>

          {/* Unfinished Button */}
          <Button
            fullWidth
            variant="contained"
            sx={{
              bgcolor: "#6f7381",
              color: "white",
              "&:hover": { bgcolor: "#6f7381" },
              textTransform: "none",
              borderRadius: "20px",
              boxShadow: "none",
              fontSize: "14px",
              marginBottom: 4,
            }}
          >
            Unfinished
          </Button>
        </Box>
      </Box>
    </Mobile>
  );
};

export default InvitationBonus;
