import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import "./Game.css";
import { Link } from "react-router-dom";

const row1 = [
  {
    name: "Popular",
    bgSrc: "/assets/top1.png",
  },
  {
    name: "Lottery",
    bgSrc: "/assets/top2.png",
  },
  {
    name: "Casino",
    bgSrc: "/assets/top3.png",
  },
];
const row2 = [
  {
    name: "mid",
    bgSrc: "/assets/Big card.png",
  },
];
const row3 = [
  {
    name: "Fishing",
    bgSrc: "/assets/Rummy (1).png",
  },
  {
    name: "Original",
    bgSrc: "/assets/Fishing.png",
  },
];

const gameData = [
  {
    id: 1,
    title: "Win Go 30s",
    imgSrc: "/assets/lotterycategory_20240110062051do1k.png",
    game: "Win Go",
    path: "/timer/30sec", // Updated path for 1Min Win Go
  },
  {
    id: 2,
    title: "Win Go 1Min",
    imgSrc: "/assets/lotterycategory_20240110062051do1k.png",
    game: "Win Go",
    path: "/timer/1min", // Updated path for 1Min Win Go
  },
  {
    id: 3,
    title: "Win Go 3Min",
    imgSrc: "/assets/lotterycategory_20240110062051do1k.png",
    game: "Win Go",
    path: "/timer/3min", // Updated path for 3Min Win Go
  },
  {
    id: 4,
    title: "Win Go 5Min",
    imgSrc: "/assets/lotterycategory_20240110062051do1k.png",
    game: "Win Go",
    path: "/timer/5min", // Updated path for 5Min Win Go
  },
  {
    id: 5,
    title: "K3 1Min",
    imgSrc: "/assets/lotterycategory_20240110062111bt8e.png",
    game: "K3",
    path: "/k3/1min", // Updated path for 1Min K3
  },
  {
    id: 6,
    title: "K3 3Min",
    imgSrc: "/assets/lotterycategory_20240110062111bt8e.png",
    game: "K3",
    path: "/k3/3min", // Updated path for 3Min K3
  },
  {
    id: 7,
    title: "K3 5Min",
    imgSrc: "/assets/lotterycategory_20240110062111bt8e.png",
    game: "K3",
    path: "/k3/5min",
  },
  {
    id: 8,
    title: "K3 10Min",
    imgSrc: "/assets/lotterycategory_20240110062111bt8e.png",
    game: "K3",
    path: "/k3/10min",
  },
  {
    id: 9,
    title: "TRX 1Min",
    imgSrc: "/assets/lotterycategory_20240110062124qut6.png",
    game: "Trx Win",
    path: "/trx/1min",
  },
  {
    id: 10,
    title: "TRX 3Min",
    imgSrc: "/assets/lotterycategory_20240110062124qut6.png",
    game: "Trx Win",
    path: "/trx/3min",
  },
  {
    id: 11,
    title: "TRX 5Min",
    imgSrc: "/assets/lotterycategory_20240110062124qut6.png",
    game: "Trx Win",
    path: "/trx/5min",
  },
  {
    id: 12,
    title: "TRX 10Min",
    imgSrc: "/assets/lotterycategory_20240110062124qut6.png",
    game: "Trx Win",
    path: "/trx/10min",
  },
  {
    id: 13,
    title: "Aviator",
    imgSrc: "/assets/aviator.png",
    game: "Aviator",
    path: "/home",
  },
];

const Loteria = [
  {
    id: 1,
    imgSrc: "/assets/20240830_115012.png",
    game: "Win Go",
    path: "/timer/30sec",
  },
  {
    id: 2,
    imgSrc: "/assets/20240830_115031.png",
    game: "k3",
    path: "/k3/1min",
  },
  {
    id: 3,
    imgSrc: "/assets/20240830_114902.png",
    game: "trx",
    path: "/trx/1min",
  },
];

const Characteristic = [
  {
    id: 1,
    imgSrc: "/assets/800_20240807164906191.png",
    game: "Aviator",
    odds: 88.77,
  },
  { id: 2, imgSrc: "/assets/100.png", game: "boom", odds: 88.77 },
  { id: 3, imgSrc: "/assets/110.png", game: "limbo", odds: 88.77 },
  { id: 4, imgSrc: "/assets/107.png", game: "hotline", odds: 88.77 },
  { id: 5, imgSrc: "/assets/101.png", game: "hilo", odds: 88.77 },
  { id: 6, imgSrc: "/assets/14075.png", game: "fortune neko", odds: 88.77 },
];

const Hotslot = [
  {
    id: 1,
    imgSrc: "/assets/800_20240601192244182.jpg",
    game: "Win Go",
    odds: 88.77,
  },
  { id: 2, imgSrc: "/assets/22001.png", game: "Win Go", odds: 81.83 },
  { id: 3, imgSrc: "/assets/98.png", game: "Win Go", odds: 88.84 },
  { id: 4, imgSrc: "/assets/royal.png", game: "Win Go", odds: 84.17 },
  { id: 5, imgSrc: "/assets/Ganesh.png", game: "K3", odds: 80.04 },
  { id: 6, imgSrc: "/assets/rabbit.png", game: "K3", odds: 91.46 },
];

const Original = [
  { id: 1, imgSrc: "/assets/800_20240807164906191.png", game: "Aviator" },
  { id: 2, imgSrc: "/assets/100.png", game: "Boom" },
  { id: 3, imgSrc: "/assets/902.png", game: "Win Go" },
  { id: 4, imgSrc: "/assets/803.png", game: "Aviator 5min" },
  { id: 5, imgSrc: "/assets/802.png", game: "Aviator 3min" },
  { id: 6, imgSrc: "/assets/114.png", game: "Horse racing" },
  { id: 7, imgSrc: "/assets/110.png", game: "limbo" },
  { id: 8, imgSrc: "/assets/107.png", game: "hotline" },
  { id: 9, imgSrc: "/assets/101.png", game: "hilo" },
  { id: 10, imgSrc: "/assets/197.png", game: "color game" },
  { id: 11, imgSrc: "/assets/200.png", game: "pappu playing" },
  { id: 12, imgSrc: "/assets/224.png", game: "go rush" },
  { id: 13, imgSrc: "/assets/229.png", game: "mines" },
  { id: 14, imgSrc: "/assets/232.png", game: "tower" },
  { id: 15, imgSrc: "/assets/233.png", game: "hilo" },
  { id: 16, imgSrc: "/assets/235.png", game: "limbo" },
  { id: 17, imgSrc: "/assets/236.png", game: "wheel" },
];

const Slots = [
  {
    id: 1,
    imgSrc: "/assets/card1.png",
    game: "jili",
  },
  { id: 2, imgSrc: "/assets/card2.png", game: "pg" },
  { id: 3, imgSrc: "/assets/card3.png", game: "jdb" },
  { id: 4, imgSrc: "/assets/card4.png", game: "cq" },
  { id: 5, imgSrc: "/assets/card5.png", game: "n" },
  {
    id: 6,
    imgSrc: "/assets/card6.png",
    game: "evolution",
  },
  { id: 7, imgSrc: "/assets/card1.png", game: "mn" },
];

const Sports = [
  { id: 1, imgSrc: "/assets/sport1.png" },
  { id: 2, imgSrc: "/assets/sport2.png" },
  { id: 3, imgSrc: "/assets/sport3.png" },
];

const Casino = [
  { id: 1, imgSrc: "/assets/roulette1.png", game: "roulette1" },
  { id: 2, imgSrc: "/assets/dragontigar.png", game: "dragontigar" },
  { id: 3, imgSrc: "/assets/roulette3.png", game: "roulette3" },
];

const Pvc = [
  { id: 1, imgSrc: "/assets/365games.png" },
  { id: 2, imgSrc: "/assets/v8poker.png" },
];

const Fishing = [
  { id: 1, imgSrc: "/assets/AB3 (1).png" },
  { id: 2, imgSrc: "/assets/AT01.png" },
  { id: 3, imgSrc: "/assets/AT05.png" },
  { id: 4, imgSrc: "/assets/GO02.png" },
  { id: 5, imgSrc: "/assets/67001.png" },
  { id: 6, imgSrc: "/assets/7001.png" },
  { id: 7, imgSrc: "/assets/7002.png" },
  { id: 8, imgSrc: "/assets/7003.png" },
  { id: 9, imgSrc: "/assets/7004.png" },
  { id: 10, imgSrc: "/assets/7005.png" },
  { id: 11, imgSrc: "/assets/7006.png" },
  { id: 12, imgSrc: "/assets/7007.png" },
  { id: 14, imgSrc: "/assets/119.png" },
  { id: 15, imgSrc: "/assets/20.png" },
  { id: 16, imgSrc: "/assets/212.png" },
  { id: 17, imgSrc: "/assets/32.png" },
  { id: 18, imgSrc: "/assets/42.png" },
  { id: 19, imgSrc: "/assets/60.png" },
  { id: 20, imgSrc: "/assets/71.png" },
  { id: 21, imgSrc: "/assets/74.png" },
  { id: 22, imgSrc: "/assets/82.png" },
  { id: 23, imgSrc: "/assets/510.png" },
  { id: 24, imgSrc: "/assets/SFG_WDFuWaFishing.png" },
  { id: 25, imgSrc: "/assets/SFG_WDGoldBlastFishing.png" },
  { id: 26, imgSrc: "/assets/SFG_WDGoldenFortuneFishing.png" },
  { id: 27, imgSrc: "/assets/SFG_WDGoldenFortuneFishing.png" },
  { id: 28, imgSrc: "/assets/SFG_WDGoldenTyrantFishing.png" },
  { id: 29, imgSrc: "/assets/SFG_WDMerryIslandFishing.png" },
];

const categoriesMap = {
  Characteristic: Characteristic,
  Hotslot: Hotslot,
  Original: Original,
  Slots: Slots,
  Sports: Sports,
  Casino: Casino,
  Pvc: Pvc,
  Fishing: Fishing,
};

const Games = () => {
  const numberOfOriginalItems = Original.length;
  const numberOfHotSlotItems = Hotslot.length;
  const numberOfcharacteristicItems = Characteristic.length;
  const numberOfSlotsItems = Slots.length;
  const numberOfSportsItems = Sports.length;
  const numberOfCasinoItems = Casino.length;
  const numberOfpvcItems = Pvc.length;
  const numberOfFishingItems = Fishing.length;
  const numberOfLoteriaItems = Loteria.length;
  const [tabValue, setTabValue] = useState(0);
  const [activeItem, setActiveItem] = useState("Win Go");

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const filteredGameData =
    activeItem === "All"
      ? gameData
      : gameData.filter((game) => game.title.includes(activeItem));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/head"); // Navigate to the specified link
  };
  const handleClick1 = () => {
    navigate("/k3"); // Navigate to the specified link
  };
  const handleClick2 = () => {
    navigate("/trx"); // Navigate to the specified link
  };

  const [currentIndexes, setCurrentIndexes] = useState({
    Loteria: 0,
    Characteristic: 0,
    Hotslot: 0,
    Original: 0,
    Slots: 0,
    Sports: 0,
    Casino: 0,
    Pvc: 0,
    Fishing: 0,
  });
  const handlePrev = (category) => {
    if (!categoriesMap[category]) return;
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [category]:
        (prevIndexes[category] -
          1 +
          Math.ceil(categoriesMap[category]?.length / 3)) %
        Math.ceil(categoriesMap[category]?.length / 3),
    }));
  };

  const handleNext = (category) => {
    if (!categoriesMap[category]) return; // Check if the category exists
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [category]:
        (prevIndexes[category] + 1) %
        Math.ceil(categoriesMap[category]?.length / 3),
    }));
  };

  return (
    <Box>
      <Container
        maxWidth="lg"
        style={{ paddingLeft: "24px", paddingRight: "10px", marginTop: "5%" }}
      >
        {/* First row with 3 images */}
        <Grid container spacing={2} justifyContent="center">
          {row1.map((item, index) => (
            <Grid item xs={4} key={index} style={{ padding: "6px" }}>
              <Button
                style={{
                  padding: 0,
                  minWidth: "unset",
                  borderRadius: "8px",
                  maxWidth: "220px", // Limit max width to prevent cutting
                }}
              >
                <img
                  src={item.bgSrc}
                  alt={item.name}
                  style={{
                    width: "100%", // Full width of the button
                    height: "auto", // Auto height to maintain aspect ratio
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                />
              </Button>
            </Grid>
          ))}
        </Grid>

        {/* Second row with 1 image */}
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ marginTop: "5px" }}
        >
          {row2.map((item, index) => (
            <Grid
              item
              xs={12}
              key={index}
              style={{
                padding: "8px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  padding: 0,
                  minWidth: "unset",
                  borderRadius: "8px",
                  maxWidth: "500px", // Max width for larger image
                  width: "100%", // Ensure it stays within the container
                }}
              >
                <img
                  src={item.bgSrc}
                  alt={item.name}
                  style={{
                    width: "100%", // Full width of the button
                    height: "auto", // Auto height to maintain aspect ratio
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                />
              </Button>
            </Grid>
          ))}
        </Grid>

        {/* Third row with 2 images */}
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ marginTop: "1px" }}
        >
          {row3.map((item, index) => (
            <Grid item xs={6} key={index} style={{ padding: "8px" }}>
              <Button
                style={{
                  padding: 0,
                  minWidth: "unset",
                  borderRadius: "8px",
                  maxWidth: "300px", // Limit max width for the third row
                }}
              >
                <img
                  src={item.bgSrc}
                  alt={item.name}
                  style={{
                    width: "100%", // Full width of the button
                    height: "auto", // Auto height to maintain aspect ratio
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                />
              </Button>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Box sx={{ maxWidth: 600, margin: "auto", padding: 1 }}>
        <Typography
          variant="h5"
          color="#d9ac4e"
          fontFamily=" bahnschrift"
          fontWeight="bold"
          textAlign="left"
        >
          | Lottery
        </Typography>
        <Box
          sx={{
            background: "#3A3A3A",
            color: "#a6a9ae",
            overflowX: "auto", // Horizontal scrolling enabled
            overflowY: "hidden",
            maxWidth: "100%", // Ensure the container takes full width of the parent
            width: { xs: "100%", md: "25rem" }, // Adjust width for different screen sizes
            height: "3.5rem",
            display: "flex",
            alignItems: "center",

            // Scrollbar styling
            "&::-webkit-scrollbar": {
              height: "8px", // Adjust scrollbar height
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#d5aa57", // Color of scrollbar thumb
              borderRadius: "10px", // Round the thumb
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#3A3A3A", // Color of scrollbar track
            },
            scrollbarWidth: "thin", // For Firefox browsers
          }}
        >
          <Box
            component="ul"
            sx={{
              width: "max-content", // Makes sure content takes up the necessary width
              fontSize: 0,
              whiteSpace: "nowrap",
              padding: 0,
              margin: 0,
              display: "flex",
              alignItems: "center",
              listStyle: "none",
            }}
          >
            {["All", "Win Go", "K3", "TRX", "Aviator"].map((item, index) => (
              <Box
                component="li"
                key={index}
                sx={{
                  display: "inline-block",
                  padding: "0 1rem",
                  fontWeight: 500,
                  fontSize: "1rem",
                  textAlign: "center",
                  borderRadius: "0.9rem",
                  background:
                    item === activeItem
                      ? "linear-gradient(to bottom, #f5e1a1, #d5aa57)"
                      : "transparent",
                  color: item === activeItem ? "#3A3A3A" : "#a6a9ae",
                  margin: "0 .5rem",
                  lineHeight: "3.5rem",
                  cursor: "pointer",
                }}
                className={item === activeItem ? "active" : ""}
                onClick={() => handleItemClick(item)}
              >
                {item}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto", padding: 1 }}>
        <Box sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
            {filteredGameData.map((game) => (
              <Grid item xs={4} sm={4} md={4} key={game.id}>
                <Link to={game.path} style={{ textDecoration: "none" }}>
                  <Box
                    sx={{
                      position: "relative",
                      borderRadius: "8px",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={game.imgSrc}
                      alt={game.title}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        padding: "8px 0",
                        color: "#ffee69",
                        fontWeight: "bold",
                        fontSize: "13px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                      }}
                    >
                      {game.title}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* characteristic */}
      <Box sx={{ px: 2 }}>
        <Typography
          variant="h5"
          color="#d9ac4e"
          fontFamily=" bahnschrift"
          fontWeight="bold"
          textAlign="left"
        >
          | Popular
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto", padding: 0 }}>
        <Box sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
            {Characteristic.map((game) => (
              <Grid item xs={4} sm={4} md={4} key={game.id}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={game.imgSrc}
                    alt={game.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      backgroundColor: "#f2f2f1",
                      borderRadius: "10px",
                      marginTop: "8px",
                      overflow: "hidden",
                      width: "120px",
                      height: "25px",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${game.odds}%`,
                        background:
                          "linear-gradient(to bottom,#f9e39c, #c69643)",

                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "13px",
                          marginLeft: "5px",
                        }}
                      >
                        odds of {game.odds}%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Hot Slots Section */}
      {/* <Box sx={{ px: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <img
              src="/assets/clicksTopList-dfac71d9.png"
              alt="separator"
              style={{
                width: "22px",
                height: "22px",
                marginRight: "8px",
              }}
            />
            <Typography
              sx={{
                fontSize: "16px",
                color: "black",
                marginRight: "12px",
                whiteSpace: "nowrap",
              }}
            >
              Hot Slots
            </Typography>
            <Button
              variant="outlined"
              sx={{
                borderColor: "transparent",
                backgroundColor: "#f8f8f8",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: "#f8f8f8",
                },
                display: "flex",
                alignItems: "center",
                px: 1.5,
                py: 0.5,
                textTransform: "none",
                borderRadius: "5px",
                height: "28px",
                minWidth: "unset",
                flexShrink: 0,
              }}
            ></Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto" }}>
        <Box sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
            {Hotslot.map((game) => (
              <Grid item xs={4} sm={4} md={4} key={game.id}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={game.imgSrc}
                    alt={game.game}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      backgroundColor: "#f2f2f1",
                      borderRadius: "10px",
                      marginTop: "8px",
                      overflow: "hidden",
                      width: "120px",
                      height: "25px",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${game.odds}%`,
                        background:
                          "linear-gradient(to bottom,#f9e39c, #c69643)",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          marginLeft: "5px",
                        }}
                      >
                        odds of {game.odds}%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box> */}

      {/* Original Section */}
      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h5"
            color="#d9ac4e"
            fontFamily=" bahnschrift"
            fontWeight="bold"
            textAlign="left"
          >
            | Mini Games
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Original}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Original: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Original.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Original.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* Slot */}
      <Box sx={{ px: 2, mt: 2 }}>
        <Typography
          variant="h5"
          color="#d9ac4e"
          fontFamily=" bahnschrift"
          fontWeight="bold"
          textAlign="left"
        >
          | Slots
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto" }}>
        <Box sx={{ padding: "12px" }}>
          <Grid container spacing={1}>
            {Slots.map((game) => (
              <Grid item xs={6} sm={6} md={6} key={game.id}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={game.imgSrc}
                    alt={game.game}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Sports Section */}
      <Box sx={{ mt: 0, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h5"
            color="#d9ac4e"
            fontFamily=" bahnschrift"
            fontWeight="bold"
            textAlign="left"
          >
            | Sports
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes}
            onChangeIndex={setCurrentIndexes}
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Sports.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Sports.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={6} sm={6} md={6} key={game.id}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* Casino Section */}

      {/* <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <img
                src="/assets/video-0216ce19.png"
                alt="separator"
                style={{
                  width: "22px",
                  height: "22px",
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "black",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                Casino
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Casino}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Casino: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Casino.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Casino.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box> */}

      {/* PVC Section */}
      {/* <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <img
                src="/assets/chess-11735038.png"
                alt="separator"
                style={{
                  width: "22px",
                  height: "22px",
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "black",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                }}
              >
                PVC
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Pvc}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Pvc: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Pvc.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Pvc.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box> */}

      {/* Fishing Section */}
      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h5"
            color="#d9ac4e"
            fontFamily=" bahnschrift"
            fontWeight="bold"
            textAlign="left"
          >
            | Fishing
          </Typography>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Fishing}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Fishing: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Fishing.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Fishing.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>
    </Box>
  );
};

export default Games;
