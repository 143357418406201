import React from 'react';
import './CoinBox.css';
// import coinimg from '../../../public/assets/cointri.png';

function CoinBox({ coinboxAmount, coinboxDay, onClick, disabled }) {
  return (
    <div 
      className={`coinbox-container ${disabled ? 'disabled' : ''}`} 
      onClick={disabled ? null : onClick}
    >
      <div className='coinbox-amount' style={{color: "white"}}>{coinboxAmount}</div>
      <div className="coinbox-image">
        <img src="../../../assets/cointri.png" alt="" />
      </div>
      <div className="coinbox-day" style={{color: "a8a588"}}>{coinboxDay}</div>
    </div>
  );
}

export default CoinBox;