import AdminPanel from './Admin';
import CreateSalaryMain from './CreateSalaryMain';

const BonusSetting = ({}) => {
  return (
    <AdminPanel>
      <CreateSalaryMain />
    </AdminPanel>
  );
};

export default BonusSetting;