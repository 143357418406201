import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Container,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import Mobile from "../Components/Mobile";
import { useBank } from "./BankContext";

const banks = [
  "Bank of Baroda",
  "Union Bank of India",
  "Central Bank of India",
  "Yes Bank",
  "HDFC Bank",
  "Karnataka Bank",
  "Standard Chartered Bank",
  "IDBI Bank",
  "Bank of India",
  "Punjab National Bank",
  "ICICI Bank",
  "Canara Bank",
  "Kotak Mahindra Bank",
  "State Bank of India",
  "Indian Bank",
  "Axis Bank",
  "Syndicate Bank",
  "Citibank India",
];

function ChooseBank() {
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [customBank, setCustomBank] = useState("");
  const navigate = useNavigate();
  const bankContext = useBank();

  if (!bankContext) {
    console.error(
      "Bank context is undefined. Make sure ChooseBank is wrapped in BankProvider."
    );
    return <Typography>Error: Bank context not available</Typography>;
  }

  const { setSelectedBank } = bankContext;

  const filteredBanks = banks.filter((bank) =>
    bank.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleBankClick = (bank) => {
    if (setSelectedBank) {
      setSelectedBank(bank);
      navigate(-1);
    } else {
      console.error("setSelectedBank is undefined");
    }
  };

  const handleCustomBankSubmit = () => {
    if (setSelectedBank) {
      setSelectedBank(customBank);
      navigate(-1);
      setCustomBank(""); // Clear the custom bank name after submission
      setOpenDialog(false); // Close the dialog
    } else {
      console.error("setSelectedBank is undefined");
    }
  };

  return (
    <Mobile>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(var(--vh, 1vh) * 150)"
        position="relative"
        sx={{ backgroundColor: "#242424" }}
      >
        <Box flexGrow={1}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "#3f3f3f",
              padding: "5px 0",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Grid item xs={2}>
              <IconButton
                sx={{ color: "#fff", marginRight: "1rem" }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{
                  color: "#fff",
                  flexGrow: 1,
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                Choose a bank
              </Typography>
            </Grid>
            <Grid item xs={2} />
          </Grid>

          <Box sx={{ padding: "0px", backgroundColor: "#333332" }}>
            <TextField
              fullWidth
              variant="filled"
              placeholder="Search bank"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                borderRadius: "4px",
                "& .MuiFilledInput-root": {
                  backgroundColor: "#333332", // Background color of the input field
                  color: "#a8a5a1", // Input text color
                  "&:hover": {
                    backgroundColor: "#333332", // Ensure background doesn't change on hover
                  },
                  "&.Mui-focused": {
                    backgroundColor: "#333332", // Ensure background doesn't change when focused
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#a8a5a1", // Input text color
                },
                "& .MuiInputLabel-root": {
                  color: "#a8a5a1", // Label color
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#d9ac4e" }} />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: { color: "#a8a5a1" }, // Change placeholder color
              }}
            />
          </Box>

          <Container sx={{ flexGrow: 1, overflowY: "auto", padding: "12px" }}>
            <Box
              sx={{
                backgroundColor: "#333332",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ padding: "16px", color: "#d9ac4e" }}
              >
                Choose a bank
              </Typography>
              <List>
                {filteredBanks.map((bank) => (
                  <ListItem
                    button
                    key={bank}
                    onClick={() => handleBankClick(bank)}
                    sx={{
                      borderBottom: "1px solid #e0e0e0",
                      "&:last-child": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <ListItemText
                      primary={bank}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color:
                            bank === "FEDERAL BANK" ? "#a8a5a1" : "#a8a5a1",
                        },
                      }}
                    />
                  </ListItem>
                ))}
                <ListItem
                  button
                  onClick={() => setOpenDialog(true)}
                  sx={{
                    borderBottom: "1px solid #e0e0e0",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <ListItemText
                    primary="Other"
                    primaryTypographyProps={{
                      sx: {
                        color: "#a8a5a1", // Change the text color to #a8a5a1
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Box>
          </Container>

          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            PaperProps={{
              sx: {
                backgroundColor: "#23222d", // Set background color of the Dialog
                color: "#a8a5a1", // Set text color for the Dialog
              },
            }}
          >
            <DialogTitle sx={{ color: "#d9ac4e" }}>
              Enter Custom Bank Name
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Bank Name"
                type="text"
                fullWidth
                variant="outlined"
                sx={{ backgroundColor: "#333332", color: "#a8a5a1" }}
                value={customBank}
                onChange={(e) => setCustomBank(e.target.value)}
                InputLabelProps={{
                  sx: { color: "#a8a5a1" }, // Change the label color
                }}
                InputProps={{
                  sx: { color: "#a8a5a1" }, // Change the input text color
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenDialog(false)}
                sx={{
                  color: "#a8a5a1", // Cancel button color
                  "&:hover": { color: "#d9ac4e" }, // Hover effect for Cancel button
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCustomBankSubmit}
                sx={{
                  backgroundColor: "#d9ac4e", // Submit button background color
                  color: "#23222d", // Submit button text color
                  "&:hover": {
                    backgroundColor: "#c69643", // Hover effect for Submit button
                  },
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Mobile>
  );
}

export default ChooseBank;