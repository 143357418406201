import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Box,
  IconButton,
  Typography,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import CasinoIcon from "@mui/icons-material/Casino";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import SmsIcon from "@mui/icons-material/Sms";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Mobile from "../Components/Mobile";
import { domain } from "../Components/config";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CalendarDrawer from "./CalendarDrawer";
import "./BetHistoryStyles.css";

const buttons = [
  {
    label: "Lottery",
    icon: "/assets/Lottery Deselect.png",
    activeIcon: "/assets/Lottery Select.png" // Add your active image path
  },
  {
    label: "Casino",
    icon: "/assets/Casino Deselect.png",
    activeIcon: "/assets/Casino Select.png" // Add your active image path
  },
  {
    label: "Rummy",
    icon: "/assets/Rummy Deselect.png",
    activeIcon: "/assets/Rummy Select.png" // Add your active image path
  },
  {
    label: "Slots",
    icon: "/assets/Slots Deselect.png",
    activeIcon: "/assets/Slots Selected.png" // Add your active image path
  },
];

const filterOptions = {
  Lottery: ["All", "Wingo", "K3", "TRX", "5D"],
  Casino: ["Game A", "Game B", "Game C"],
  Rummy: ["Deposits", "Withdrawals"],
  Slots: ["GameA", "GameB", "Special Game For You"],
};

const BetHistoryMain = () => {
  const [activeButton, setActiveButton] = useState("Lottery");
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    filterOptions["Lottery"][0]
  );
  const [dateRange, setDateRange] = useState([null, null]);
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [calendarDrawerOpen, setCalendarDrawerOpen] = useState(false);
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: null,
    end: null,
  });

  const toggleCalendarDrawer = (open) => {
    setCalendarDrawerOpen(open);
  };

  const toggleFilterDrawer = (open) => {
    setFilterDrawerOpen(open);
  };

  const handleDateRangeSelect = (dateRange) => {
    console.log("Date range selected:", dateRange);
    setSelectedDateRange(dateRange);
  };

  const formatDateRangeDisplay = () => {
    const { start, end } = selectedDateRange;
    if (start && end) {
      return `${new Date(start).toLocaleDateString()} - ${new Date(
        end
      ).toLocaleDateString()}`;
    } else if (start) {
      return new Date(start).toLocaleDateString();
    }
    return "Choose a date";
  };

  const fetchBets = async () => {
    setLoading(true);
    setError("");
    try {
      if (activeButton !== "Lottery") {
        // If the activeButton is not "Lottery", show no data available
        setBets([]);
        setError("No data available");
      } else {
        const response = await axios.get(
          `${domain}/bet-history`,
          { withCredentials: true },
          {
            params: {
              gameType:
                activeButton === "Lottery" && selectedOption !== "All"
                  ? selectedOption
                  : null,
              startDate: dateRange[0] ? dateRange[0].toISOString() : null,
              endDate: dateRange[1] ? dateRange[1].toISOString() : null,
            },
          }
        );

        if (response.data && Array.isArray(response.data)) {
          if (selectedOption === "All") {
            // For "Lottery" with "All" option, handle the response directly
            console.log("response.data--->", response.data);
            setBets(response.data || []);
          } else {
            // Handle the case where specific gameType is selected
            if (response.data.length > 0) {
              // Set bets from response directly if there are bets
              console.log("response.data.bets---->", response.data);
              setBets(response.data);
            } else {
              // No bets or empty bets
              setBets([]);
              setError("No history available");
            }
          }
        } else {
          // Response data is not an array or is malformed
          setBets([]);
          setError("Invalid data format received");
        }
      }
    } catch (err) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBets(); // Fetch bets when component mounts or when filters change
  }, [activeButton, selectedOption, dateRange]);

  const handleButtonClick = (label) => {
    setActiveButton(label);
    setSelectedFilter(null);
    setSelectedOption(filterOptions[label][0]); // Automatically select the first option
  };

  const handlePage = () => {
    navigate(-1);
  };

  const handleFilterClick = (filter) => {
    if (filter === "Date") {
      setCalendarOpen(true);
    } else {
      setSelectedFilter(filter);
      setSelectedOption(filterOptions[activeButton][0]); // Automatically select the first option
      setDrawerOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setCalendarOpen(false);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDrawerOpen(false);
    fetchBets(); // Fetch bets after option selection
  };
  function capitalizeFirstLetter(str) {
    if (typeof str !== "string") return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function getK3SelectedItem(bet) {
    if (bet.totalSum) {
      return `Total Sum: ${bet.totalSum}`;
    }
    if (bet.twoSameOneDifferent && bet.twoSameOneDifferent.length) {
      return `Two Same One Different: ${bet.twoSameOneDifferent.join(", ")}`;
    }
    if (bet.threeSame && bet.threeSame.length) {
      return `Three Same: ${bet.threeSame.join(", ")}`;
    }
    if (bet.threeDifferentNumbers && bet.threeDifferentNumbers.length) {
      return `Three Different Numbers: ${bet.threeDifferentNumbers.join(", ")}`;
    }
    return "N/A";
  }

  function calculateTotalBetAmount(bet) {
    const totalBet = parseFloat(bet.totalBet) || 0;
    const tax = parseFloat(bet.tax) || 0;
    return Math.round(totalBet + tax);
  }

  return (
    <div>
      <Mobile>
        <Box
          display="flex"
          flexDirection="column"
          height="calc(var(--vh, 1vh) * 100)"
          position="relative"
        >
          <Box flexGrow={1}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "#3f3f3f ",
                padding: "7px 4px",
                color: "white",
              }}
            >
              <Grid item container alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                  <IconButton
                    sx={{ color: "#f5f3f0", mr: 8 }}
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#f5f3f0",
                      flexGrow: 1,
                      textAlign: "center",
                      mr: 10,
                    }}
                  >
                    Bet History
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Box
              ref={containerRef}
              sx={{
                display: "flex",
                overflowX: "auto",
                scrollSnapType: "x mandatory",
                WebkitOverflowScrolling: "touch",
                padding: "15px 0",
                margin: "0 16px",
                gap: "6px",
                scrollbarWidth: "none",
                "-ms-overflow-style": "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                transition: "scroll 0.3s ease-out",
              }}
            >
              {buttons.map((button) => (
               <Box
               key={button.label}
               sx={{
                 minWidth: "100px", // Ensures button has enough width
                 flex: "0 0 auto", // Prevents button from shrinking
                 scrollSnapAlign: "start", // Aligns with the start of the scroll container
                 margin: "8px", // Space between buttons
               }}
             >
               <IconButton
                 onClick={() => handleButtonClick(button.label)}
                 sx={{
                   display: "flex",
                   flexDirection: "column", // Align icon and label vertically
                   alignItems: "center",
                   justifyContent: "center",
                   width: "100%",
                   minHeight: "60px",
                   background:
                     activeButton === button.label
                       ? "linear-gradient(135deg, #f4db93, #c69643)" // Active tab gradient
                       : "#333332", // Inactive tab background
                   color:
                     activeButton === button.label ? "#ffffff" : "#a6b1d5",
                   borderRadius: "8px",
                   padding: "8px",
                   transition: "background 0.3s ease", // Transition on background change
                   "&:hover": {
                     background:
                       activeButton === button.label
                         ? "linear-gradient(135deg, #f1d78f, #ca9c4a)" // Active tab hover gradient
                         : "#333332", // Inactive tab hover background
                   },
                   "& .MuiTab-iconWrapper": {
                     marginBottom: "4px", // Space between icon and label
                   },
                 }}
               >
                 <img
                   src={activeButton === button.label ? button.activeIcon : button.icon}
                   alt={button.label}
                   style={{ width: "24px", height: "24px", marginBottom: "4px" }}
                 />
                 <Typography
                   variant="caption"
                   sx={{
                     textTransform: "none",
                     fontSize: "0.75rem",
                     fontWeight: "500",
                   }}
                 >
                   {button.label}
                 </Typography>
               </IconButton>
             </Box>             
              ))}
            </Box>

            <Box
              sx={{
                padding: "0 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#333332",
                  color: "#f5f3f0",
                  borderColor: "#333332",
                  borderRadius: 1,
                  padding: "10px 20px",
                  textTransform: "capitalize",
                  justifyContent: "space-between",
                  width: "100%",
                  "&:hover": {
                    backgroundColor:
                      selectedOption === selectedOption ? "#333332" : "#333332",
                    borderColor:
                      selectedOption === selectedOption ? "#333332" : "#333332",
                  },
                }}
                onClick={() => handleFilterClick("Game")}
              >
                {selectedOption === "All" ? "All Games" : selectedOption}
                <KeyboardArrowDownOutlinedIcon />
              </Button>
              {/* <Button
                variant="outlined"
                sx={{
                  color: "black",
                  borderColor: "#ffffff",
                  borderRadius: 1,
                  padding: "10px 20px",
                  textTransform: "capitalize",
                  width: "100%",
                  justifyContent: "space-between",
                  marginLeft: "8px",
                  backgroundColor: "#ffffff",
                  "&:hover": {
                    backgroundColor:
                      selectedOption === selectedOption ? "#ffffff" : "#ffffff",
                    borderColor:
                      selectedOption === selectedOption ? "#ffffff" : "#ffffff",
                  },
                }}
                onClick={() => handleFilterClick("Date")}
              >
                Select Date
                <KeyboardArrowDownOutlinedIcon />
              </Button> */}
              <Button
                variant="outlined"
                onClick={() => toggleCalendarDrawer("Date")}
                // onClick={() => handleFilterClick("Date")}
                sx={{
                  color: "#f5f3f0",
                  borderColor: "#333332",
                  borderRadius: 1,
                  padding: "10px 20px",
                  textTransform: "capitalize",
                  width: "100%",
                  justifyContent: "space-between",
                  marginLeft: "8px",
                  backgroundColor: "#333332",
                  "&:hover": {
                    backgroundColor:
                      selectedOption === selectedOption ? "#333332" : "#333332",
                    borderColor:
                      selectedOption === selectedOption ? "#333332" : "#333332",
                  },
                }}
              >
                Select Date
                <KeyboardArrowDownOutlinedIcon />
              </Button>
            </Box>

            {loading ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="calc(100vh - 80px)"
              >
                <CircularProgress />
              </Box>
            ) : error ? (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="calc(100vh - 80px)"
              >
                <Typography color="error">{error}</Typography>
              </Box>
            ) : (
              <Box padding="16px">
                {bets.length === 0 ? (
                  <Typography>No data available</Typography>
                ) : (
                  bets.map((bet, index) => (
                    <div key={index} className="Card">
                      <div className="card-header">
                        <div className="card-header-left">
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            textAlign="left"
                            color="#f5f3f0"
                          >
                            {bet && bet.gameType
                              ? String(bet.gameType).charAt(0).toUpperCase() +
                                String(bet.gameType).slice(1)
                              : "N/A"}
                          </Typography>
                          <Typography variant="caption" color="#d29136">
                            {new Date(bet.timestamp).toLocaleString()}
                          </Typography>
                        </div>
                        <div className="card-header-right">
                          <Typography
                            variant="body1"
                            style={{
                              color: bet.status === "Won" ? "green" : "#40ad72",
                            }}
                          >
                            {bet.status === "Failed" || bet.status === "lost"
                              ? "Lose"
                              : bet.status}
                          </Typography>
                        </div>
                      </div>

                      <div className="card-content">
                        <div className="card-row">
                          <span className="label">Type</span>
                          <span className="value" style={{color:"#f5f3f0"}}>
                            {" "}
                            {bet && bet.gameType
                              ? `${
                                  String(bet.gameType).charAt(0).toUpperCase() +
                                  String(bet.gameType).slice(1)
                                } ${bet.selectedTimer}ute`
                              : "N/A"}
                          </span>
                        </div>
                        <div className="card-row">
                          <span className="label">Period</span>
                          <span className="value" style={{color:"#f5f3f0"}}>{bet.periodId}</span>
                        </div>
                        <div className="card-row">
                          <span className="label">Order number</span>
                          <span className="value" style={{color:"#f5f3f0"}}>{bet.orderId || "N/A"}</span>
                        </div>
                        <div className="card-row">
                          <span className="label">Select</span>
                          <span className="value" style={{color:"#f5f3f0"}}>
                            {bet.gameType === "k3"
                              ? getK3SelectedItem(bet)
                              : capitalizeFirstLetter(bet.selectedItem)}
                          </span>
                        </div>
                        <div className="card-row">
                          <span className="label">Total bet</span>
                          <span className="value" style={{color:"#f5f3f0"}}>₹{bet.betAmount}</span>
                        </div>

                        <div
                          className="lottery-results-heading"
                          style={{ textAlign: "left",color:"#a8a5a1" }}
                        >
                          Lottery results
                        </div>

                        {bet.gameType === "wingo" && (
                          <div className="lottery-results">
                            <div className="result-item number">
                              {bet.result}
                            </div>
                            <div className="result-item size">{bet.size}</div>
                            <div className="result-item color">{bet.color}</div>
                          </div>
                        )}

                        {bet.gameType === "k3" && (
                          <div className="lottery-results">
                            <span className="value">
                              {bet.diceOutcome
                                ? bet.diceOutcome.join(", ")
                                : "N/A"}
                            </span>
                          </div>
                        )}

                        {bet.gameType === "trx" && (
                          <div className="lottery-results">
                            <span className="value">{bet.result}</span>
                          </div>
                        )}

                        <div className="results-grid">
                          <div className="grid-item">
                            <div className="value" style={{color:"#f5f3f0"}}>
                              ₹
                              {(
                                Math.round(bet.totalBet + bet.tax) - bet.tax
                              ).toFixed(2)}
                            </div>
                            <div className="label">Actual amount</div>
                          </div>
                          <div className="grid-item">
                            <div className="value" style={{color:"#f5f3f0"}}>
                              ₹
                              {bet.status === "Won"
                                ? (
                                    parseFloat(bet.winLoss) +
                                    parseFloat(bet.betAmount)
                                  ).toFixed(2)
                                : "0.00"}
                            </div>
                            <div className="label">Winnings</div>
                          </div>
                          <div className="grid-item">
                            <div className="value" style={{color:"#f5f3f0"}}>
                              ₹{parseFloat(bet.tax).toFixed(2)}
                            </div>
                            <div className="label">Handling fee</div>
                          </div>
                          <div className="grid-item">
                            <div
                              className="value"
                              style={{
                                color: bet.status === "Won" ? "green" : "red",
                              }}
                            >
                              {bet.status === "Won" ? "+" : "-"}₹
                              {Math.abs(parseFloat(bet.winLoss)).toFixed(2)}
                            </div>
                            <div className="label">Profit/loss</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </Box>
            )}
          </Box>

          <Drawer
  anchor="bottom"
  open={drawerOpen}
  onClose={handleDrawerClose}
  sx={{
    "& .MuiDrawer-paper": {
      width: "100%",
      height: "auto",
      margin: "0 auto",
      maxWidth: isSmallScreen ? "600px" : "396px",
      background: "linear-gradient(to bottom, #27252e, #333332,#27252e)", // Gradient background
      color: "#f5f3f0",
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
  }}
>
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "5px",
      // marginTop: "10px",
      backgroundColor: "#333332",
    }}
  >
    <Button
      onClick={handleDrawerClose}
      sx={{
        color: "#f5f3f0", // Light color for contrast
        fontWeight: "normal",
      }}
    >
      Cancel
    </Button>
    <Button
      sx={{
        color: "#d9ac4f", // Golden color for emphasis
        fontWeight: "bold",
      }}
    >
      Confirm
    </Button>
  </Box>
  <List sx={{ "& .MuiListItem-root": { padding: "5px 0" } }}>
    {filterOptions[activeButton].map((option) => (
      <ListItem
        button
        key={option}
        onClick={() => handleOptionSelect(option)}
        sx={{
          color: option === "All" ? "#f5f3f0" : "#f5f3f0",
          fontWeight: option === "All" ? "bold" : "normal",
          borderBottom: "1px solid #333332",
          textAlign: "center",
        }}
      >
        <ListItemText primary={option} />
      </ListItem>
    ))}
  </List>
</Drawer>


          {/* <Drawer
            anchor="bottom"
            open={calendarOpen}
            onClose={handleDrawerClose}
          >
            <Box sx={{ padding: "16px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Start"
                  endText="End"
                  value={dateRange}
                  onChange={(newValue) => setDateRange(newValue)}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField
                        {...startProps}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                      <Box sx={{ mx: 2 }}>to</Box>
                      <TextField
                        {...endProps}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: "16px", width: "100%" }}
                onClick={() => {
                  setCalendarOpen(false);
                  fetchBets(); // Fetch bets after date range selection
                }}
              >
                Apply Date Range
              </Button>
            </Box>
          </Drawer> */}

          <CalendarDrawer
            isOpen={calendarDrawerOpen}
            onClose={() => toggleCalendarDrawer(false)}
            onRangeSelect={handleDateRangeSelect}
          />
        </Box>
      </Mobile>
    </div>
  );
};

export default BetHistoryMain;