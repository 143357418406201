import React from "react";
import SettingsMain from "../Components/SettingsMain";
import BottomNavigationArea from "../Components/BottomNavigation";

const Settings = () => {
  return (
    <div>
      <SettingsMain>
        <BottomNavigationArea />
      </SettingsMain>
    </div>
  );
};

export default Settings;
