import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Activity from "./Pages/Activity";
import Promotion from "./Pages/Promotion";
import Account from "./Pages/Account";
import Wallet from "./Pages/Wallet";
import Head from "./Game/Head";
import LotteryApp from "./Game/5d";
import LotteryAppk from "./Game/K3";
import LotteryAppt from "./Game/Trx";
import { useAuth } from "./contexts/AuthContext";
import Timer from "./Components/Timer";
import RechargeMain from "./Components/RechargeMain";
import WithdrawMain from "./Components/WithDrawMain";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import DepositHistory from "./Pages/DepositHistory";
import BetHistory from "./Pages/BetHistory";
import WithdrawHistory from "./Pages/WithdrawHistory";
import Transaction from "./Pages/Transaction";
import CommisionDetailsMain from "./Components/CommisionDetailsMain";
import SubordinateDataMain from "./Components/SubordinateDataMain";
import CoupenUser from "./Pages/CoupenUser";
import Invite from "./Pages/Invite";
import PaymentComponent from "./Components/WowPayment";
import InvitiationRules from "./Pages/InvitiationRules";
import Messages from "./Pages/Messages";
import Settings from "./Pages/Settings";
import Language from "./Pages/Language";
import Newsubordinate from "./Pages/Newsubordinate";
import Attendance from "./Pages/Attendance";
import { AuthProvider } from "./contexts/AuthContext";
import ProtectedRoute from "./Components/ProtectedRoute";
import Testing from "./Components/testing";
import Addbank from "./Pages/addbank";
import Vip from "./Pages/Vip";
import AdminProtectedRoute from "./Components/AdminProtectedRoute";
import Members from "./Admin/Components/Member";
import DashboardMain from "./Admin/Components/DashboardMain";
import Coupen from "./Admin/Components/coupen";
import SettingsAdmin from "./Admin/Components/SettingsAdmin";
import Withdraw from "./Admin/Components/Withdraw";
import DepositRequest from "./Admin/Components/DepositRequest";
import DepositPendingRequest from "./Admin/Components/DepositPendingRequest";
import Notification from "./Admin/Components/Notification";
import BonusSetting from "./Admin/Components/BonusSetting";
import Profile from "./Admin/Components/Profile";
import Wingo from "./Admin/Components/WinGo";
import VIPLevelComponent from "./Admin/Components/VIPLevelComponent";
import WalletUpdate from "./Admin/Components/WalletUpdate";
import Support from "./Pages/Support";
import SupportAdmin from "./Admin/Components/SupportAdmin";
import PlayersSalary from "./Admin/Components/PlayersSalary";
import CreateSalary from "./Admin/Components/CreateSalary";
import OnePage from "./Pages/OpenPage";
import WithdrawlLimits from "./Admin/Components/WithdrawlLimits";
import WithdrawStatus from "./Admin/Components/WithdrawStatus";
import AdminPanel from "./Admin/Components/Admin";
import K3 from "./Admin/Components/K3";
import UpdateImages from "./Admin/Components/UpdateImages";
import DetailsMain from "./Components/DetailsMain";
import GameStatistics from "./Components/GameStatistics";
import ActivityDetail from "./Components/ActivityDetail";
import ActivityFirstRecharge from "./Components/ActivityFirstRecharge";
import ForgotPassword from "./Pages/ForgotPassword";
import Rebate from "./Components/Rebate";
import SuperJackpot from "./Components/SuperJackpot";
import Rule from "./Components/Rule";
import WinningStar from "./Components/WinningStar";
import ActivityDetails from "./Components/ActivityDetails";
import ChooseBank from "./Pages/ChooseBank";
import { BankProvider } from "./Pages/BankContext";
import UsdtAddress from "./Components/UsdtAddress";
import MaintenancePage from "./Components/MaintenancePage";
import PuzzleComponent from "./Components/PuzzleComponent";
import ChatZone from "./Components/ChatSection";
import CustomerService from "./Components/CustomerService";
import AboutUsPage from "./Components/AboutPage";
import FeedbackPage from "./Components/FeedbackPage";
import DepositModal from "./Pages/depositModal";
import GamesGrid from "./Components/LoateryPage";
import NotificationContainer from "./Components/NotificationContainer";
import BankDetailsEdit from "./Admin/Components/BankDetailsEdit";
import GameRules from "./Components/GameRules";
import WinstreakBonus from "./Components/WinstreakBonus";
import YouTubeCreative from "./Components/YouTubeCreative";
import InvitationBonus from "./Components/InvitationBonus"
import RebateLevelComponent from "./Components/RebateRatio"
import UpdateTurnOver from "./Admin/Components/UpdateTurnOver";


const gameData = [
  {
    id: 1,
    title: "Win Go 1Min",
    imgSrc: "/assets/lotterycategory_20240110062051do1k.png",
    game: "Win Go",
    path: "/timer/1min",
  },
  {
    id: 2,
    title: "Win Go 3Min",
    imgSrc: "/assets/lotterycategory_20240110062051do1k.png",
    game: "Win Go",
    path: "/timer/3min",
  },
  {
    id: 3,
    title: "Win Go 5Min",
    imgSrc: "/assets/lotterycategory_20240110062051do1k.png",
    game: "Win Go",
    path: "/timer/5min",
  },
  {
    id: 4,
    title: "Win Go 30Sec", // Updated title for 30 seconds
    imgSrc: "/assets/lotterycategory_20240110062051do1k.png",
    game: "Win Go",
    path: "/timer/30sec", // Updated path for 30 seconds
  },
];

const k3GameData = [
  {
    id: 5,
    title: "K3 1Min",
    imgSrc: "/assets/lotterycategory_20240110062111bt8e.png",
    game: "K3",
    path: "/k3/1min",
  },
  {
    id: 6,
    title: "K3 3Min",
    imgSrc: "/assets/lotterycategory_20240110062111bt8e.png",
    game: "K3",
    path: "/k3/3min",
  },
  {
    id: 7,
    title: "K3 5Min",
    imgSrc: "/assets/lotterycategory_20240110062111bt8e.png",
    game: "K3",
    path: "/k3/5min",
  },
  {
    id: 8,
    title: "K3 10Min",
    imgSrc: "/assets/lotterycategory_20240110062111bt8e.png",
    game: "K3",
    path: "/k3/10min",
  },
];

const trxGameData = [
  {
    id: 9,
    title: "TRX 1Min",
    imgSrc: "/assets/lotterycategory_20240110062124qut6.png",
    game: "Trx Win",
    path: "/trx/1min",
  },
  {
    id: 10,
    title: "TRX 3Min",
    imgSrc: "/assets/lotterycategory_20240110062124qut6.png",
    game: "Trx Win",
    path: "/trx/3min",
  },
  {
    id: 11,
    title: "TRX 5Min",
    imgSrc: "/assets/lotterycategory_20240110062124qut6.png",
    game: "Trx Win",
    path: "/trx/5min",
  },
  {
    id: 12,
    title: "TRX 10Min",
    imgSrc: "/assets/lotterycategory_20240110062124qut6.png",
    game: "Trx Win",
    path: "/trx/10min",
  },
];

const extractTimerKey = (path) => {
  const parts = path.split("/");
  return parts[2];
};

const extractK3TimerKey = (path) => {
  const parts = path.split("/");
  return parts[2];
};

const App = () => {
  const isAuthenticated = useAuth();
  if (isAuthenticated === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <AuthProvider>
      <div style={{ backgroundColor: "whitesmoke" }}>
        <Router>
          <Routes>
            <Route path="/register" element={<Register />} />

            <Route
              path={"/testing"}
              element={
                <AdminProtectedRoute>
                  <Testing />
                </AdminProtectedRoute>
              }
            />

            <Route
              path={"/members"}
              element={
                <AdminProtectedRoute>
                  <Members />
                </AdminProtectedRoute>
              }
            />

            <Route
              path={"/dashboard"}
              element={
                <AdminProtectedRoute>
                  <DashboardMain />
                </AdminProtectedRoute>
              }
            />

            <Route
              path={"/create-coupon"}
              element={
                <AdminProtectedRoute>
                  <Coupen />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={"/settings-admin"}
              element={
                <AdminProtectedRoute>
                  <SettingsAdmin />
                </AdminProtectedRoute>
              }
            />

            <Route
              path={"/withdraw-admin"}
              element={
                <AdminProtectedRoute>
                  <WithdrawStatus />
                </AdminProtectedRoute>
              }
            />

            <Route
              path={"/withdraw-admin-status"}
              element={
                <AdminProtectedRoute>
                  <Withdraw />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={"/vip-levels"}
              element={
                <AdminProtectedRoute>
                  <AdminPanel>
                    <VIPLevelComponent />
                  </AdminPanel>
                </AdminProtectedRoute>
              }
            />
            <Route
              path={"/recharge-admin"}
              element={
                <AdminProtectedRoute>
                  <DepositRequest />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={"/pending-recharge-admin"}
              element={
                <AdminProtectedRoute>
                  <DepositPendingRequest />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={"/edit-user-bank-details"}
              element={
                <AdminProtectedRoute>
                  <BankDetailsEdit/>
                </AdminProtectedRoute>
              }
            />
            <Route
              path={"/notifications-admin"}
              element={
                <AdminProtectedRoute>
                  <Notification />
                </AdminProtectedRoute>
              }
            />

            <Route
              path={"/bonus-settings"}
              element={
                <AdminProtectedRoute>
                  <BonusSetting />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/profile/:userId"
              element={
                <AdminProtectedRoute>
                  <Profile />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/wingo-admin"
              element={
                <AdminProtectedRoute>
                  <Wingo />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/wallet-update"
              element={
                <AdminProtectedRoute>
                  <WalletUpdate />
                </AdminProtectedRoute>
              }
            />
            <Route
              path="/update-turn-over"
              element={
                <AdminProtectedRoute>
                  <UpdateTurnOver />
                </AdminProtectedRoute>
              }
            />
            <Route
              path="/create-salary"
              element={
                <AdminProtectedRoute>
                  <CreateSalary />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/support-admin"
              element={
                <AdminProtectedRoute>
                  <SupportAdmin />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/playersSalary"
              element={
                <AdminProtectedRoute>
                  <PlayersSalary />
                </AdminProtectedRoute>
              }
            />

            <Route
              path="/withdrawl-limits"
              element={
                <AdminProtectedRoute>
                  <WithdrawlLimits />
                </AdminProtectedRoute>
              }
            />

            <Route path="/timer" element={<Timer />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/deposit-modal" element={<DepositModal />} />
            <Route
              path="/activity"
              element={
                <ProtectedRoute>
                  <Activity />
                </ProtectedRoute>
              }
            />
            <Route
              path="/activity/FirstRecharge"
              element={
                <ProtectedRoute>
                  <ActivityFirstRecharge />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/invitationbonus"
              element={
                <ProtectedRoute>
                  <InvitationBonus />
                </ProtectedRoute>
              }
            />

            <Route
              path="/activity/ActivityDetail"
              element={
                <ProtectedRoute>
                  <ActivityDetail />
                </ProtectedRoute>
              }
            />
            <Route path="/support" element={<Support />} />
            <Route
              path="/coupen-user"
              element={
                <ProtectedRoute>
                  <CoupenUser />
                </ProtectedRoute>
              }
            />
            <Route path="/customer-service" element={<CustomerService />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route
              path="/coupen-user"
              element={
                <ProtectedRoute>
                  <CoupenUser />
                </ProtectedRoute>
              }
            />
             <Route
              path="/rebate-ratio"
              element={
                <ProtectedRoute>
                  <RebateLevelComponent />
                </ProtectedRoute>
              }
            />
            <Route path="/vip" element={<Vip />} />
            <Route
              path="/transaction"
              element={
                <ProtectedRoute>
                  <Transaction />
                </ProtectedRoute>
              }
            />

            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />

            <Route
              path="/5d"
              element={
                <ProtectedRoute>
                  <LotteryApp />
                </ProtectedRoute>
              }
            />
            {k3GameData.map((game) => {
              const timerKey = extractK3TimerKey(game.path);
              return (
                <Route
                  key={game.id}
                  path={game.path}
                  element={
                    <ProtectedRoute>
                      <LotteryAppk timerKey={timerKey} />
                    </ProtectedRoute>
                  }
                />
              );
            })}
            <Route
              path="/addbank"
              element={
                <ProtectedRoute>
                  <Addbank />
                </ProtectedRoute>
              }
            />
            {trxGameData.map((game) => {
              const timerKey = extractTimerKey(game.path);
              return (
                <Route
                  key={game.id}
                  path={game.path}
                  element={
                    <ProtectedRoute>
                      <LotteryAppt timerKey={timerKey} />
                    </ProtectedRoute>
                  }
                />
              );
            })}
            <Route
              path="/subordinate-data"
              element={
                <ProtectedRoute>
                  <SubordinateDataMain />
                </ProtectedRoute>
              }
            />
            <Route
              path="/commision-details"
              element={
                <ProtectedRoute>
                  <CommisionDetailsMain />
                </ProtectedRoute>
              }
            />
            <Route
              path="/details"
              element={
                <ProtectedRoute>
                  <DetailsMain />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bet-history"
              element={
                <ProtectedRoute>
                  <BetHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/deposit-history"
              element={
                <ProtectedRoute>
                  <DepositHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/withdraw-history"
              element={
                <ProtectedRoute>
                  <WithdrawHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/withdraw"
              element={
                <ProtectedRoute>
                  <WithdrawMain />
                </ProtectedRoute>
              }
            />
            <Route
              path="/recharge"
              element={
                <ProtectedRoute>
                  <RechargeMain />
                </ProtectedRoute>
              }
            />
            <Route
              path="/promotion"
              element={
                <ProtectedRoute>
                  <Promotion />
                </ProtectedRoute>
              }
            />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route
              path="/game-statistics"
              element={
                <ProtectedRoute>
                  <GameStatistics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/wallet"
              element={
                <ProtectedRoute>
                  <Wallet />
                </ProtectedRoute>
              }
            />
            {gameData.map((game) => {
              const timerKey = extractTimerKey(game.path);
              return (
                <Route
                  key={game.id}
                  path={game.path}
                  element={
                    <ProtectedRoute>
                      <Head timerKey={timerKey} />
                    </ProtectedRoute>
                  }
                />
              );
            })}
            <Route
              path="/invite"
              element={
                <ProtectedRoute>
                  <Invite />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment"
              element={
                <ProtectedRoute>
                  <PaymentComponent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/invitation-rules"
              element={
                <ProtectedRoute>
                  <InvitiationRules />
                </ProtectedRoute>
              }
            />
            <Route
              path="/messages"
              element={
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/language"
              element={
                <ProtectedRoute>
                  <Language />
                </ProtectedRoute>
              }
            />
            <Route
              path="/newsubordinate"
              element={
                <ProtectedRoute>
                  <Newsubordinate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/attendance"
              element={
                <ProtectedRoute>
                  <Attendance />
                </ProtectedRoute>
              }
            />

            <Route
              path="/update-images"
              element={
                <ProtectedRoute>
                  <UpdateImages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/k3-admin"
              element={
                <ProtectedRoute>
                  <K3 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rebate"
              element={
                <ProtectedRoute>
                  <Rebate />
                </ProtectedRoute>
              }
            />

            <Route
              path="/superjackpot"
              element={
                <ProtectedRoute>
                  <SuperJackpot />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rule"
              element={
                <ProtectedRoute>
                  <Rule />
                </ProtectedRoute>
              }
            />

            <Route
              path="/winningstar"
              element={
                <ProtectedRoute>
                  <WinningStar />
                </ProtectedRoute>
              }
            />

            <Route
              path="/activitydetails"
              element={
                <ProtectedRoute>
                  <ActivityDetails />
                </ProtectedRoute>
              }
            />

            <Route
              path="/choosebank"
              element={
                <ProtectedRoute>
                  <ChooseBank />
                </ProtectedRoute>
              }
            />
            <Route
              path="/usdtaddress"
              element={
                <ProtectedRoute>
                  <UsdtAddress />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat/:ticketId"
              element={
                <ProtectedRoute>
                  <ChatZone />
                </ProtectedRoute>
              }
            />
            <Route
              path="all-games"
              element={
                <ProtectedRoute>
                  <GamesGrid />
                </ProtectedRoute>
              }
            />
            <Route
              path="game-rules"
              element={
                <ProtectedRoute>
                  <GameRules />
                </ProtectedRoute>
              }
            />
            <Route
              path="/activity/WinstreakBonus"
              element={
                <ProtectedRoute>
                  <WinstreakBonus />
                </ProtectedRoute>
              }
            />
            <Route
              path="/activity/YouTubeCreative"
              element={
                <ProtectedRoute>
                  <YouTubeCreative />
                </ProtectedRoute>
              }
            />
            <Route path="/" element={<OnePage />} />
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
};
export default App;
